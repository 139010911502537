import { HandleResponseJson, HandleResponseError  } from './api'

/*
[
  {
    "id": 0,
    "name": "string",
    "size": "string",
    "note": "string",
    "downloadLink": "string",
    "createAt": "string",
    "updatedAt": "string"
  }
]
*/
export function APIGetOEMOrderItemFiles(itemId, callback) {
    return async dispatch => {
        try {
            const url = `/api/customer/oem/order/item/${itemId}/file`
            let rsp = await fetch(url)
            const json = await HandleResponseJson(rsp)
            const data = json.data
            const errorMessage = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, data, errorMessage)
        } catch (err) {
            console.error("API ERROR: ", err)
            callback(false, undefined, err)
        }
    }
}

/*
Request Payload
{
  "file": {}
}
Response
{
  "id": 0,
  "name": "string",
  "size": "string",
  "note": "string",
  "downloadLink": "string",
  "createAt": "string",
  "updatedAt": "string"
}
*/
export function APICreateOEMOrderItemFile(itemId, file, note, callback) {
    return async dispatch => {
        try {
            const url = `/api/customer/oem/order/item/${itemId}/file?note=${encodeURIComponent(note)}`
            let formData = new FormData()
            formData.append('file', file)
            let rsp = await fetch(url, {
                method:'POST',
                body: formData
            })
            const json = await HandleResponseJson(rsp)
            const error = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, error)
        } catch(err) {
            console.error("API ERROR: ", err)
            callback(false, err)
        }
    }
}

export function APIDeleteOEMOrderItemFile(fileId, callback) {
    return async dispatch => {
        try {
            const url = `/api/customer/oem/order/item/file/${fileId}`
            let rsp = await fetch(url, {
                method:'DELETE'
            })
            const isSuccess = HandleResponseError(rsp, dispatch)
            const json = await HandleResponseJson(rsp)
            const error = json.message
            callback(isSuccess, error)
        } catch (err) {
            console.error("API ERROR: ", err)
            callback(false, err)
        }
    }
}

/*
{
  "id": 0,
  "name": "string",
  "size": "string",
  "note": "string",
  "downloadLink": "string",
  "createAt": "string",
  "updatedAt": "string"
}
*/
export function APIGetOEMOrderItemFile(fileId, callback) {
    if (typeof fileId === 'undefined' || fileId === null || fileId.length === 0) {
        return async dispatch => {
            callback(false, undefined, "No File ID")
        }
    }
    return async dispatch => {
        try {
            const url = `/api/customer/oem/order/item/file/${fileId}`
            let rsp = await fetch(url)
            const json = await HandleResponseJson(rsp)
            const data = json.data
            const error = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, data, error)
        } catch (err) {
            console.error("API ERROR: ", err)
            callback(false, undefined, err)
        }
    }
}

/*
Request Payload
{}
Response
{
  "id": 0,
  "name": "string",
  "size": "string",
  "note": "string",
  "downloadLink": "string",
  "createAt": "string",
  "updatedAt": "string"
}
*/
export function APIUpdateOEMOrderItemFile(fileId, note, callback) {
    return async dispatch => {
        try {
            const url = `/api/customer/oem/order/item/file/${fileId}?note=${encodeURIComponent(note)}`
            let rsp = await fetch(url, {
              method:'PUT'
            })
            const json = await HandleResponseJson(rsp)
            const data = json.data
            const error = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, data, error)
        } catch (err) {
            console.error("API ERROR: ", err)
            callback(false, undefined, err)
        }
    }
}