import { bindActionCreators } from '@reduxjs/toolkit'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import './oemOrderCard.css'
import {
    APICreateOEMOrderContact,
    APIDeleteOEMOrderContact,
    APIGetOEMOrderContacts,
    APIUpdateOEMOrderContact
} from '../../apis/oemOrderContact'
import { withRouter } from '../../common/withRouter'
import './oemOrderCardContacts.css'
import PlusLogo from "../../assert/plus.svg"
import { Button, Modal, Spinner } from 'react-bootstrap'
import { AddressDropDown } from '../../common/addressDropDown'

class OEMOrderCardContacts extends Component {

    constructor() {
        super()
        this.state = {
            isLoadingData: true,
            contacts: [],
            isShowingEditContact: false,
            editContact: undefined,
            isEditingContact: false,
            editContactError: undefined
        }
    }

    componentDidMount() {
        this.loadAllContacts()
    }

    loadAllContacts() {
        const orderId = this.props.order?.id
        if (typeof orderId !== 'undefined') {
            this.props.APIGetOEMOrderContacts(orderId, (success, data, error) => {
                this.setState({
                    isLoadingData: false,
                    contacts: data || []
                })
            })
        }
    }

    addContactAction = () => {
        this.setState({
            isEditingContact: true
        })
        this.props.APICreateOEMOrderContact(
            this.props.order.id,
            this.state.editContact,
            (success, error) => {
                this.loadAllContacts()
                this.setState({
                    isEditingContact: false,
                    isShowingEditContact: !success,
                    editContactError: error
                })
            })
    }

    editContactAction = () => {
        this.setState({
            isEditingContact: true
        })
        this.props.APIUpdateOEMOrderContact(
            this.state.editContact.id,
            this.state.editContact,
            (success, data, error) => {
                this.loadAllContacts()
                this.setState({
                    editContact: data,
                    isEditingContact: false,
                    isShowingEditContact: !success,
                    editContactError: error
                })
            })
    }

    deleteContactAction = () => {
        this.setState({
            isEditingContact: true
        })
        this.props.APIDeleteOEMOrderContact(
            this.state.editContact.id,
            (success, error) => {
                this.loadAllContacts()
                this.setState({
                    isEditingContact: false,
                    isShowingEditContact: !success,
                    editContactError: error
                })
            })
    }

    onValueChange = e => {
        this.setState(preState => {
            return {
                ...preState,
                editContact: {
                    ...preState.editContact,
                    [e.target.name]:e.target.value
                }
            }
        })
    }

    renderEditItem = (title, key, isRequired) => {
        return (
            <div style={{ fontSize: '14px', display: 'flex', marginBottom: '8px', alignItems: 'center' }}>
                <label style={{ textAlign: 'right', width: '100px', marginRight: '8px', fontWeight: 500 }}>
                    {title}
                    {isRequired && <span style={{ color: 'red' }}>&nbsp;*</span>}
                </label>
                <input style={{ flexGrow: 1 }} type="text" id={key} name={key} defaultValue={this.state.editContact?.[key]} onChange={this.onValueChange} />
            </div>
        )
    }

    renderEditContactModal = () => {
        const isNew = this.state.editContact?.id === undefined
        const system = this.props.userState.system || {}
        let currCountry = this.state.editContact?.['country']
        if (currCountry === undefined) {
            currCountry = 'US'
            this.onValueChange({ target: { name: 'country', value: 'US' } })
        }
        const countryRow = (
            <div style={{ fontSize: '14px', display: 'flex', marginBottom: '8px', alignItems: 'center' }}>
                <label className="col-form-label" style={{ textAlign: 'right', width: '100px', marginRight: '8px', fontWeight: 500 }}>Country</label>
                <div>
                    <AddressDropDown choiceKey='country' choiceValue={currCountry} choices={system.countries} onClick={(e) => {
                        this.onValueChange(e)
                    }} />
                </div>
            </div>
        )
        const states = (system.countries?.find(c => c.abbrev.toLowerCase() === currCountry.toLowerCase()) || { states: [] }).states
        let currState = this.state.editContact?.['state']
        const stateRow = (
            <div style={{ fontSize: '14px', display: 'flex', marginBottom: '8px', alignItems: 'center' }}>
                <label className="col-form-label" style={{ textAlign: 'right', width: '100px', marginRight: '8px', fontWeight: 500 }}>Country</label>
                <div>
                    <AddressDropDown choiceKey='state' choiceValue={currState} choices={states} onClick={(e) => {
                        this.onValueChange(e)
                    }} />
                </div>
            </div>
        )
        return (
            <Modal size='md' show={ this.state.isShowingEditContact } onHide={ () => { this.setState({ isShowingEditContact: false }) } }>
                <div className='CommonDialogHeader'>
                    <h3 className='CommonDialogTitle'>{isNew ? "Add" : "Edit"} Contact</h3>
                </div>
                <hr style={{ margin: '0px' }}/>
                <Modal.Body>
                <form>
                    { this.renderEditItem('Name', 'name', true) }
                    { this.renderEditItem('Title', 'title') }
                    { this.renderEditItem('Duty', 'duty', true) }
                    { this.renderEditItem('Email', 'email') }
                    { this.renderEditItem('Phone', 'phone') }
                    { this.renderEditItem('Fax', 'fax') }
                    { this.renderEditItem('Address', 'address') }
                    { this.renderEditItem('City', 'city') }
                    { this.renderEditItem('Postal', 'postal') }
                    { countryRow }
                    { stateRow}
                </form>
                <span className='CommonErrorMessage'>{this.state.editContactError}</span>
                </Modal.Body>
                <Modal.Footer className='CommonDialogFooter'>
                    <Button
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px',
                            paddingTop: '0px',
                            paddingBottom: '0px',
                            paddingLeft: '8px',
                            paddingRight: '8px'
                        }}
                        className='shadow-none'
                        variant="secondary"
                        onClick={ () => { this.setState({ isShowingEditContact: false }) } }>
                        CLOSE
                    </Button>
                    <div style={{ flexGrow: 1 }}/>
                    {this.state.isEditingContact === true && <Spinner animation='border' style={{ alignSelf: 'center', marginRight: '16px'}} />}
                    { !isNew && (
                        <Button
                            style={{
                                marginLeft: '8px',
                                marginRight: '8px',
                                height: '32px',
                                fontSize: '14px',
                                paddingTop: '0px',
                                paddingBottom: '0px',
                                paddingLeft: '8px',
                                paddingRight: '8px'
                            }}
                            className='shadow-none'
                            variant="danger"
                            disabled={ this.state.isEditingContact}
                            onClick={ () => {
                                this.deleteContactAction()
                            }}>
                            DELETE
                        </Button>
                    )}
                    <Button
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px',
                            paddingTop: '0px',
                            paddingBottom: '0px',
                            paddingLeft: '8px',
                            paddingRight: '8px'
                        }}
                        className='shadow-none'
                        variant="warning"
                        disabled={ this.state.isEditingContact}
                        onClick={ () => {
                            isNew ?
                                this.addContactAction() :
                                this.editContactAction()
                        }}>
                        {isNew ? 'ADD' : 'SAVE'}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderContact = (contact) => {
        return <div key={contact.id}
            className='contactCard'
            onClick={async () => {
                this.setState({
                    isShowingEditContact: true,
                    editContact: contact
                })
            }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1
            }}>
                <div className='contactCardText'>
                    <span>{(contact.name || "").toUpperCase()}</span>
                </div>
                <div className='contactCardText'>
                    <span>{(contact.title || "").toUpperCase()}</span>
                </div>
                <div className='contactCardText'>
                    <span>{(contact.duty || "").toUpperCase()}</span>
                </div>
                <div className='contactCardText'>
                    <span>{(contact.email || "").toUpperCase()}</span>
                </div>
                <div className='contactCardText'>
                    <span>{(contact.phone || "").toUpperCase()}</span>
                </div>
                <div className='contactCardText'>
                    <span>{(contact.fax || "").toUpperCase()}</span>
                </div>
                <div className='contactCardText'>
                    <span>{(contact.address || "").toUpperCase()}</span>
                </div>
                <div className='contactCardText'>
                    <span>{(contact.city || "").toUpperCase()}</span>
                </div>
                <div className='contactCardText'>
                    <span>{(contact.state || "").toUpperCase()}</span>
                    <span> </span>
                    <span>{(contact.postal || "").toUpperCase()}</span>
                </div>
                <div className='contactCardText'>
                    <span>{(contact.country || "").toUpperCase()}</span>
                </div>
            </div>
        </div>
    }

    renderNewContact = () => {
        return <div key='newAddress'
                    className='addContactCard'
                    onClick={() => {
                        this.setState({
                            isShowingEditContact: true,
                            editContact: {}
                        })
                    }}>
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center' }}>
                <img src={PlusLogo} style={{ width: '32px', height: '32px', alignSelf: 'center', userSelect: 'none' }} alt="Plus" />
                <span style={{ userSelect: 'none', width: '100%', fontSize: '13px' }}>Add Contact</span>
            </div>
        </div>
    }

    render() {
        return (
            <>
                { this.state.isLoadingData && (
                    <div style={{ textAlign: 'center' }}>
                        <Spinner animation='border' style={{ marginTop: '80px', marginBottom: '80px'}} />
                    </div>
                )}
                { !this.state.isLoadingData && (
                    <div className='contactsContainer'>
                        { this.state.contacts.map(item => { return this.renderContact(item) }) }
                        { this.renderNewContact() }
                    </div>
                )}
                {this.renderEditContactModal()}
            </>
        )
    }
}

const mapStateToProps = state => ({
    userState:state.userState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        APIGetOEMOrderContacts,
        APICreateOEMOrderContact,
        APIDeleteOEMOrderContact,
        APIUpdateOEMOrderContact
    }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OEMOrderCardContacts))