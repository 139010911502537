import { Helmet } from 'react-helmet';
// import NewArrivals from "./newarrivals";
// import BestSellers from "./bestsellers";
// import { Gallery } from "./gallery";
// import { Team } from "./team";
import { About } from "./about";
import { Catalogue } from "./catalogue"
import { Contact } from "./contact";
import { MobileApps } from "./mobileapps";
import { Partners } from "./partners";
// import Distributors from "./distributors";
// import { OEM } from "./oem";
import "./landing.css";
import LandingCarousel from "./carousel";
// import CleaningMaintenance from "./cleaningMaintenance";
import Spotlights from './spotlights';
import { Promotions } from './promotions';

function Landing() {
  return (
    <div className="padding-top-58 landing">
      <Helmet>
          <title>Outdoor Sports Hunting & Shooting Accessories</title>
      </Helmet>
      <Promotions />
      <LandingCarousel />
      <Spotlights />
      {/* <OEM /> */}
      {/* <CleaningMaintenance /> */}
      {/* <NewArrivals />
      <BestSellers /> */}
      {/* <Gallery /> */}
      <About />
      {/* <Team /> */}
      <Partners />
      {/* <Distributors /> */}
      <Catalogue />
      <MobileApps />
      <Contact />
    </div>
  )
}
  
export default Landing;