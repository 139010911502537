import { useParams } from "react-router-dom"

export const Footer = (props) => {
  var region = useParams().region ?? ""
  if (region.length > 0) {
      region = `/${region}`
  }
  return (
    <footer id='footer' style={{ padding: '30px 0'}}>
        <h6 style={{ textAlign: 'center', color: '#333'}}>&copy; {`${new  Date().getFullYear()}`} McJ TOOLS</h6>
        <div className="container">
          <div className="d-flex text-center align-items-center justify-content-center">
            <a href={`${region}/warranty`} rel='nofollow'>
              Warranty
            </a>
            <a href={`${region}/privacy`} rel='nofollow'>
              Privacy
            </a>
          </div>
        </div>
    </footer>
  )
}