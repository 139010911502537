import { createSlice } from '@reduxjs/toolkit'

var index = 0

const authSlice = createSlice({
  name: "authSlice",
  initialState: {},
  reducers: {
    signUp: (state, action) => {
      state.authed = action.payload.authed
      state.error = action.payload.error
    },
    signIn: (state, action) => {
      state.authed = action.payload.authed
      state.error = action.payload.error
      state.index = ++index
    },
    signOut: (state, action) => {
      state.authed = false;
    },
    loadSession: (state, action) => {
      state.authed = action.payload.authed;
    }
  }
})

export const { signUp, signIn, signOut, loadSession } = authSlice.actions

export default authSlice