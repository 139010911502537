import { createSlice } from '@reduxjs/toolkit'

const oemOrderSlice = createSlice({
  name: "orderSlice",
  initialState: {
    oemOrderList: [],
    oemOrderMap: {}
  },
  reducers: {
    getOEMOrders: (state, action) => {
        const payload = action.payload
        const oemOrders = payload.data ?? []
        let oemOrderMap = {}
        oemOrders.forEach(item => {
          oemOrderMap[item.id] = item
        });
        state.oemOrderMap = oemOrderMap
        state.oemOrderList = oemOrders
        state.error = payload.error
    },
    getOEMOrderInfo: (state, action) => {
        const payload = action.payload
        const oemOrder = payload.data
        state.oemOrderMap = {
          ...state.oemOrderMap,
          [oemOrder.id]: oemOrder
        }
        state.error = payload.error
    }
  },
})

export const {
    getOEMOrders,
    getOEMOrderInfo
} = oemOrderSlice.actions

export default oemOrderSlice