import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import './changepsw.css'
import {APIChangePassword, CleanChangePasswordAction} from '../../../apis/user'
import { connect } from 'react-redux'
import MCJ from '../../../common/def'

class ChangePsw extends Component {
    constructor() {
        super()
        this.state = {
            oldPassword:"",
            newPassword:"",
            newPasswordRP:""
        }
    }

    handleEditChanges = e=> {
        let target = e.target
        this.setState({
            [target.name]:target.value,
            errMsg:''
        })
    }

    getEditValue = (name) =>  {
        return this.state[name]
    }

    handleChangePassword = () => {
        if(this.state.oldPassword.length < 6) {
            this.setState({
                errMsg:"Password: minimum 6 characters required"
            })
            return
        }
        if(this.state.newPassword.length < 6) {
            this.setState({
                errMsg:"Password: minimum 6 characters required"
            })
            return
        }
        if(this.state.newPassword !== this.state.newPasswordRP) {
            this.setState({
                errMsg:'Passwords do not match'
            })
            return
        }
        this.props.APIChangePassword({
            oldPassword:this.state.oldPassword,
            newPassword:this.state.newPassword
        })
    }

    renderEditItem = (text, name) => {
        return (
            <tr key={name}>
                <td className='keyCol'><span>{text}</span></td>
                <td><input type={'password'} name={name} className='editInput' onChange={this.handleEditChanges} value={this.getEditValue(name)} /></td>
            </tr>
        )
    }

    renderContent = ()=> {
        let currentPsw = this.renderEditItem('Current Password', 'oldPassword')
        let newPsw = this.renderEditItem('New Password', 'newPassword')
        let newPswRP = this.renderEditItem('Repeat Password', 'newPasswordRP')
        return (
            <table>
                <tbody>
                    {currentPsw}
                    {newPsw}
                    {newPswRP}
                </tbody>
            </table>
        )
    }

    componentWillUnmount() {
        // clean 
        this.props.CleanChangePasswordAction()
    }

    render() {
        let content = this.renderContent()
        let btnEnable = true
        const {changePasswordStatus} = this.props.userState
        if(changePasswordStatus === MCJ.STATUS_LOADING || changePasswordStatus === MCJ.STATUS_LOAD_OK) {
            btnEnable = false
        }

        let tips = null
        if(changePasswordStatus === MCJ.STATUS_LOADING) {
            tips = 'Processing request...'
        } else if (changePasswordStatus === MCJ.STATUS_LOAD_OK) {
            tips = 'Succeeded to change password!'
        } else if (changePasswordStatus === MCJ.STATUS_ERROR) {
            tips = 'Failed to change password!'
        }

        return (
            <div>
                <div style={{ marginBottom: '16px' }}>
                    <h2>Change Password</h2>
                    <hr/>
                </div>
                <div style={{ marginTop: '16px', marginBottom: '16px' }}>
                    {content}
                    <p className='errMsg'>{this.state.errMsg}</p>
                    <p>{tips}</p>
                </div>
                <div>
                    <hr/>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant='warning' onClick={this.handleChangePassword} disabled={!btnEnable} >Change Password</Button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userState:state.userState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        APIChangePassword,
        CleanChangePasswordAction
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePsw)