import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { APIGetAddresses, APIRemoveAddress } from '../../../apis/user'
import PlusLogo from "../../../assert/plus.svg"
import { Link } from 'react-router-dom';
import './addresses.css'

class UserAddresses extends Component {

    componentDidMount() {
        this.props.APIGetAddresses()
    }

    renderAddNew = () => {
        return <div key='newAddress'
                    className='newAddressCard'
                    onClick={() => window.open('/user/addresses/edit', '_self') }>
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center' }}>
                <img src={PlusLogo} style={{ width: '32px', height: '32px', alignSelf: 'center', userSelect: 'none' }} alt="Plus" />
                <span style={{ userSelect: 'none', width: '100%', fontSize: '13px' }}>Add Address</span>
            </div>
        </div>
    }

    renderAddress = (address) => {
        return <div key={address.id} className='addressCard'>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1
                }}>
                <div className='addressCardText'>
                    <span>{address.name?.toUpperCase()}</span>
                </div>
                <div className='addressCardText'>
                    <span>{address.phone?.toUpperCase()}</span>
                </div>
                <div className='addressCardText'>
                    <span>{address.email?.toUpperCase()}</span>
                </div>
                <div className='addressCardText'>
                    <span>{address.address?.toUpperCase()}</span>
                </div>
                <div className='addressCardText'>
                    <span>{address.city?.toUpperCase()}</span>
                </div>
                <div className='addressCardText'>
                    <span>{address.state?.toUpperCase()}</span>
                    <span> </span>
                    <span>{address.postal?.toUpperCase()}</span>
                </div>
                <div className='addressCardText'>
                    <span>{address.country?.toUpperCase()}</span>
                </div>
            </div>
            <div style={{ display: 'flex', alignSelf: 'flex-end' }}>
                <Link style={{ textDecoration: 'none', fontSize: '13px' }}
                    to={`edit?addressId=${address.id}`}>
                    Edit
                </Link>
                <span style={{ marginLeft: '8px', marginRight: '8px', fontSize: '13px' }}>|</span>
                <Link style={{ color: "red", textDecoration: 'none', fontSize: '13px' }}
                    to={'#'}
                    onClick={()=>{
                    this.props.APIRemoveAddress(address.id)
                }}>
                    Remove
                </Link>
            </div>
        </div>
    }

    renderAddresses = () => {
        const { addresses } = this.props.userState
        const isEmpty = typeof addresses === 'undefined' || addresses === null || addresses.length <= 0
        if (isEmpty) {
            return <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                { this.renderAddNew() }
            </div>
        }
        return (
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                { this.renderAddNew() }
                { addresses.map(item => { return this.renderAddress(item) }) }
            </div>
        )
    }


    render(){
        return (
            <div>
                <h2>Your Addresses</h2>
                <hr/>
                {this.renderAddresses()}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userState: state.userState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        APIGetAddresses,
        APIRemoveAddress
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAddresses)