import { HandleResponseJson, HandleResponseError  } from './api'

/*
[
  {
    "id": 0,
    "name": "string",
    "title": "string",
    "duty": "string",
    "email": "string",
    "phone": "string",
    "fax": "string",
    "address": "string",
    "city": "string",
    "postal": "string",
    "state": "string",
    "country": "string",
    "createAt": "string",
    "updatedAt": "string"
  }
]
*/
export function APIGetOEMOrderContacts(orderId, callback) {
    return async dispatch => {
        try {
            const url = `/api/customer/oem/order/${orderId}/contact`
            let rsp = await fetch(url)
            const json = await HandleResponseJson(rsp)
            const data = json.data
            const errorMessage = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, data, errorMessage)
        } catch (err) {
            console.error("API ERROR: ", err)
            callback(false, undefined, err)
        }
    }
}

/*
Request Payload
{
  "name": "string",
  "title": "string",
  "duty": "string",
  "email": "string",
  "phone": "string",
  "fax": "string",
  "address": "string",
  "city": "string",
  "postal": "string",
  "state": "string",
  "country": "string"
}
Response
{
  "id": 0,
  "name": "string",
  "title": "string",
  "duty": "string",
  "email": "string",
  "phone": "string",
  "fax": "string",
  "address": "string",
  "city": "string",
  "postal": "string",
  "state": "string",
  "country": "string",
  "createAt": "string",
  "updatedAt": "string"
}
*/
export function APICreateOEMOrderContact(orderId, contact, callback) {
    return async dispatch => {
        try {
            const url = `/api/customer/oem/order/${orderId}/contact`
            let rsp = await fetch(url, {
                method:'POST',
                body: JSON.stringify(contact)
            })
            const json = await HandleResponseJson(rsp)
            const error = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, error)
        } catch(err) {
            console.error("API ERROR: ", err)
            callback(false, err)
        }
    }
}

export function APIDeleteOEMOrderContact(contactId, callback) {
    return async dispatch => {
        try {
            const url = `/api/customer/oem/order/contact/${contactId}`
            let rsp = await fetch(url, {
                method:'DELETE'
            })
            const isSuccess = HandleResponseError(rsp, dispatch)
            const json = await HandleResponseJson(rsp)
            const error = json.message
            callback(isSuccess, error)
        } catch (err) {
            console.error("API ERROR: ", err)
            callback(false, err)
        }
    }
}

/*
{
  "id": 0,
  "name": "string",
  "title": "string",
  "duty": "string",
  "email": "string",
  "phone": "string",
  "fax": "string",
  "address": "string",
  "city": "string",
  "postal": "string",
  "state": "string",
  "country": "string",
  "createAt": "string",
  "updatedAt": "string"
}
*/
export function APIGetOEMOrderContact(contactId, callback) {
    if (typeof contactId === 'undefined' || contactId === null || contactId.length === 0) {
        return async dispatch => {
            callback(false, undefined, "No Contact ID")
        }
    }
    return async dispatch => {
        try {
            const url = `/api/customer/oem/order/contact/${contactId}`
            let rsp = await fetch(url)
            const json = await HandleResponseJson(rsp)
            const data = json.data
            const error = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, data, error)
        } catch (err) {
            console.error("API ERROR: ", err)
            callback(false, undefined, err)
        }
    }
}

/*
Request Payload
{
  "name": "string",
  "title": "string",
  "duty": "string",
  "email": "string",
  "phone": "string",
  "fax": "string",
  "address": "string",
  "city": "string",
  "postal": "string",
  "state": "string",
  "country": "string"
}
Response
{
  "id": 0,
  "name": "string",
  "title": "string",
  "duty": "string",
  "email": "string",
  "phone": "string",
  "fax": "string",
  "address": "string",
  "city": "string",
  "postal": "string",
  "state": "string",
  "country": "string",
  "createAt": "string",
  "updatedAt": "string"
}
*/
export function APIUpdateOEMOrderContact(contactId, contact, callback) {
    return async dispatch => {
        try {
            const url = `/api/customer/oem/order/contact/${contactId}`
            let rsp = await fetch(url, {
                method:'PUT',
                body: JSON.stringify(contact)
            })
            const json = await HandleResponseJson(rsp)
            const data = json.data
            const error = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, data, error)
        } catch (err) {
            console.error("API ERROR: ", err)
            callback(false, undefined, err)
        }
    }
}