import React, { Component } from 'react'
import './imageGroup.css'

class ImgGroup extends Component {
    constructor() {
        super()
        this.state = {
            focus:0
        }
    }

    renderMainImg = () => {
        const {imgList, imgPrefix} = this.props
        if (typeof imgList === 'undefined' || imgList === null) {
            return null
        }
        if (imgList.length <= 0) {
            return null
        }
        let index = this.state.focus % imgList.length
        let theImg = imgList[index]
        return <img className='imgMain' alt='' src={`${imgPrefix}${theImg}`} />
    }

    onChangeFocus = (idx) => {
        this.setState({
            focus:idx
        })
    }

    renderImgPreviews = () => {
        const {imgList, imgPrefix} = this.props
        if (typeof imgList === 'undefined' || imgList === null) {
            return null
        }
        if (imgList.length <= 0) {
            return null
        }
        let focusIndex = this.state.focus % imgList.length
        let previews = imgList.map( (imgUrl,idx)=>{
            let imgStyle = 'imgPreview'
            if(idx === focusIndex) {
                imgStyle = 'imgPreview imgFoucus'
            }
            return <img key={idx} className={imgStyle} alt='' src={`${imgPrefix}${imgUrl}`} onClick={()=>this.onChangeFocus(idx)} />
        })
        return previews
    }

    render() {
        let mainImg = this.renderMainImg()
        let previews = this.renderImgPreviews()
        return (
            <div className='imgGroup'>
                <div className='imgPreviewBar'>
                    {previews}
                </div>
                <div>
                    {mainImg}
                </div>
            </div>
        )
    }
}

export default ImgGroup