import React, { Component } from 'react'
import { Helmet } from 'react-helmet';
import { bindActionCreators } from 'redux'
import './dealer.css'
import { connect } from 'react-redux'
import DealerRequest from './dealerRequest'

class Dealer extends Component {
    constructor(){
        super()
        this.state = {
        }
    }

    render() {
        return (
            <div className='container dealerContainer'>
                <Helmet>
                    <style>{'body { background-color: #1e2021; }'}</style>
                </Helmet>
                <DealerRequest />
            </div>
        )
    }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Dealer)