import MCJ from "../common/def"
import {
    getMe,
    getSystem,
    saveUserInfo,
    changePassword,
    getAddresses,
    createOrUpdateAddress
} from "../store/userSlice"
import { HandleResponseError } from "./api"

export function APIGetUserInfo() {
    return async dispatch => {
        try {
            let rsp = await fetch('/api/account/me')
            if (rsp.status === 200) {
                let data = (await rsp.json()).data
                return dispatch(getMe({
                    ret: true,
                    data
                }))
            } else {
                return dispatch(getMe({
                    ret: false
                }))
            }
        } catch (err) {
            console.error("Get me error", err)
            return dispatch(getMe({
                ret: false
            }))
        }
    }
}

export function APIGetSystem() {
    return async dispatch => {
        try {
            let rsp = await fetch('/api/system')
            if (rsp.status === 200) {
                let data = (await rsp.json()).data
                return dispatch(getSystem(data))
            } else {
                return dispatch(getSystem({"imageUrlPrefix":"https://mcjtools-assets.s3.amazonaws.com/images"}))
            }
        } catch (err) {
            return dispatch(getSystem({"imageUrlPrefix":"https://mcjtools-assets.s3.amazonaws.com/images"}))
        }
    }
}

// {
//     "company": "string",
//     "association": "string",
//     "fullName": "string",
//     "contactEmail": "string",
//     "phone": "string",
//     "fax": "string",
//     "address": "string",
//     "city": "string",
//     "state": "string",
//     "postal": "string",
//     "country": "string",
//     "dealer": true,
//     "ffLicense": "string",
//     "ffExpiration": "string",
//     "taxId": "string",
//     "note": "string"
// }
export function APISaveUserInfo(payload) {
    return async dispatch => {
        try {
            let rsp = await fetch('/api/account/me', {
                method:'put',
                body:JSON.stringify(payload)
            })
            HandleResponseError(rsp, dispatch)
            const json = await rsp.json()
            const data = json.data
            if (rsp.status === 200) {
                dispatch(APIGetUserInfo())
                dispatch(saveUserInfo({
                    ret: true,
                    data,
                    error: undefined
                }))
            } else {
                dispatch(saveUserInfo({
                    ret: false,
                    data,
                    error: json.message ?? "Failed"
                }))
            }
        } catch (err) {
            console.error('save user info error', err)
            dispatch(saveUserInfo({
                ret: false,
                error: err
            }))
        }
    }
}

export function APIChangePassword(inObj){
    return async dispatch => {
        try {
            dispatch(changePassword(MCJ.STATUS_LOADING))
            let rsp = await fetch('/api/account/password', {
                method:'put',
                body:JSON.stringify(inObj)
            })
            HandleResponseError(rsp)
            if(rsp.status === 200) {
                dispatch(changePassword(MCJ.STATUS_LOAD_OK))
            } else {
                dispatch(changePassword(MCJ.STATUS_ERROR))
            }

        } catch (err) {
            console.error('change pasword error', err)
            dispatch(changePassword(MCJ.STATUS_ERROR))
        }
    }
}

export function CleanChangePasswordAction() {
    return dispatch => {
        dispatch(changePassword(MCJ.STATUS_INIT))
    }
}

export function APIGetAddresses() {
    return async dispatch => {
        try {
            let rsp = await fetch('/api/account/me/shipping')
            HandleResponseError(rsp, dispatch)
            let data = (await rsp.json()).data
            if (rsp.status === 200) {
                return dispatch(getAddresses(data))
            }
            return dispatch(getAddresses([]))
        } catch (err) {
            console.error('get addresses error', err)
            return dispatch(getAddresses([]))
        }
    }
}

export function APICreateOrUpdateAddress(address) {
    return async dispatch => {
        try {
            dispatch(createOrUpdateAddress(MCJ.STATUS_LOADING))
            const isNewAddress = !(typeof address.id !== 'undefined' && address.id !== null)
            let rsp = await fetch(`/api/account/me/shipping${isNewAddress?'':`/${address.id}`}`, {
                method: isNewAddress ? 'post' : 'put',
                body:JSON.stringify(address)
            })
            HandleResponseError(rsp, dispatch)
            const response = await rsp.json()
            if (rsp.status===201 || rsp.status === 200) {
                dispatch(createOrUpdateAddress({
                    status:MCJ.STATUS_LOAD_OK
                }))
            } else {
                let message = response.message
                console.log("create address error, message: ", message)
                dispatch(createOrUpdateAddress({
                    status: MCJ.STATUS_ERROR,
                    errorMessage: message
                }))
            }
            dispatch(APIGetAddresses())
        } catch( err ) {
            console.error('create address error', err)
            dispatch(createOrUpdateAddress({
                status: MCJ.STATUS_ERROR,
                errorMessage: err
            }))
            dispatch(APIGetAddresses())
        }
    }
}

export function APIRemoveAddress(id) {
    return async dispatch => {
        try {
            let rsp = await fetch(`/api/account/me/shipping/${id}`, {
                method:'delete'
            })
            HandleResponseError(rsp, dispatch)
            dispatch(APIGetAddresses())
        } catch (err) {
            console.error('remove address error', err)
        }
    }
}