import React from 'react'
import { connect } from 'react-redux'
// import { Navigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import Product from './product'

function ProductWrap(props) {
    const {id} = useParams()

    const {authed} = props

    return <Product id={id} authed={authed} />
}

const mapStateToProps = state => ({
    authed: state.authState.authed
})

export default connect(mapStateToProps, null)(ProductWrap)