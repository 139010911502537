import { Link, useParams } from 'react-router-dom'
import './logo.css'

export default function Logo (props) {
  const isSmall = props.isSmall
  var region = useParams().region ?? ""
  if (region.length > 0) {
      region = `/${region}`
  }
  return (
      <Link to={`${region}/`} style={{ textDecoration: 'none' }}>
        <span className={`logo ${isSmall ? 'smallLogo' : ''}`}>
          <span className='logoA'>McJ</span>
          <span className='logoB'>TOOLS</span>
        </span>
      </Link>
    )
}
