import { bindActionCreators } from '@reduxjs/toolkit'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import './orderCard.css'
import {GetMonthName} from '../../../common/utils'
import MCJ from '../../../common/def';

class OrderCard extends Component {

    constructor() {
        super()
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const allProductsById = this.props.prodState?.allProductsById || {}
        const system = this.props.userState?.system || {}
        let imageUrlPrefix = MCJ.DefaultImgPrefix
        if (typeof system !== 'undefined' && system !== null) {
            imageUrlPrefix = system.imageUrlPrefix
        }
        const order = this.props.order
        let items = order.items
        if (typeof items === 'undefined' || items === null) {
            items = []
        }
        // render order detail
        let products = items.map(prodItem=>{
            let product = allProductsById[prodItem.productId]
            if(typeof product === 'undefined' || product === null) {
                return null
            }
            return (
                <div key={product.id}>
                    <img className='OrderCardProdImg' alt='' src={imageUrlPrefix+product.thumbnail} />
                    <div className='OrderCardProdDetails'>
                        <Link to={`/product/${product.id}`}>
                            <span>{product.name}</span>
                        </Link>
                        <div>${prodItem.price}</div>
                        <div>Quantity:{prodItem.quantity}</div>
                    </div>
                </div>
            )
        })
        
        let orderPlaceTime = new Date(order.createdAt)
        let strTime = `${GetMonthName(orderPlaceTime.getMonth())} ${orderPlaceTime.getDate()}, ${orderPlaceTime.getFullYear()}`
        return (
            <div key={order.id} className='OrderCardItem'>
                <div className='OrderCardHead'>
                    <div className='OrderCardHeadItem'>
                        <span>DATE</span>
                        <span className='OrderCardHeadItemContent'>{strTime}</span>
                    </div>
                    <div className='OrderCardHeadItem'>
                        <span>TOTAL</span>
                        <span className='OrderCardHeadItemContent'>US${order.summary?.total}</span>
                    </div>
                    {/* <div className='headItem'>
                        <span>SHIP INFORMATION</span>
                    </div> */}
                    <div className='OrderCardHeadItem'>
                        <span>ORDER # {order.id}</span>
                        <span className='OrderCardHeadItemContent'><Link to={`/order/${order.id}`}>Order Details</Link></span>
                    </div>
                </div>
                <div className='OrderCardProds'>
                    {products}
                </div>
                <div className='OrderCardBottom'> 
                    <div>Status:{order.status}</div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    authState: state.authState,
    userState: state.userState,
    prodState: state.prodState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderCard)