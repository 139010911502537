import React, { Component } from 'react'
import { Helmet } from 'react-helmet';
import { bindActionCreators } from 'redux'
import './oem.css'
import { Spinner } from 'react-bootstrap'
import { connect } from 'react-redux'
import OEMRequest from './oemRequest'
import OemOrderList from './oemOrderList';

class OEMService extends Component {
    
    constructor(){
        super()
        this.state = {
            isLoading: true
        }
    }

    componentDidMount() {
        setTimeout(()=>{
            this.setState({ isLoading: false })
        }, 500)
    }

    render() {
        const roles = this.props.userState?.userInfo?.roles
        const oemRole = roles?.filter(role => { return role.role === 'OEM'})[0]
        const hasOEMRole = oemRole !== undefined
        const showBackground = !this.state.isLoading && (roles === undefined || !hasOEMRole)
        return <div className={`container oemContainer ${ showBackground && 'backgroundImage'}`}>
            { (this.state.isLoading && roles === undefined) && (
                <div style={{ textAlign: 'center' }}>
                    <Spinner animation='border' style={{ marginTop: '80px', marginBottom: '80px'}} />
                </div>
            )}
            { showBackground && (
                <Helmet>
                    <style>{'body { background-color: #1e2021; }'}</style>
                </Helmet>
            )}
            { showBackground && <OEMRequest />}
            { (roles !== undefined && hasOEMRole) && <OemOrderList />}
        </div>
    }
}

const mapStateToProps = state => ({
    userState: state.userState,
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OEMService)