import imgAboutUs from '../../assert/about_us.jpg'

export const About = (props) => {
  return (
    <section id="about">
        <div className="container">
            <div className="row">
                <div className="col-xs-12 col-md-6">
                    <img src={imgAboutUs} style={{ width: '100%' }} alt="About US"/>
                </div>
                <div className="col-xs-12 col-md-6">
                    <div className="about-text">
                        <h2>About Us</h2>
                        <p>Mission: Makes Impossible Possible through hybrid efforts - Brain Power & Hard Working.</p>
                        <p>In 1980s, McJ Tools was founded in Montreal, Quebec, Canada. McJ Tools offers our customers tools and gunsmithing kits that are crafted by well-seasoned pros in the industry. All products are executed with principles: unique & sturdy design, top quality materials with long-lasting durability, along with industry competing prices. We make sure to put our best foot forward and give you only the best quality tools, for your most challenging projects</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};
