import { HandleResponseJson, HandleResponseError  } from './api'

/*
[
  {
    "id": 0,
    "name": "string",
    "note": "string",
    "quantity": 0,
    "price": "string",
    "files,omitempty": [
      {
        "id": 0,
        "name": "string",
        "size": "string",
        "note": "string",
        "downloadLink": "string",
        "createAt": "string",
        "updatedAt": "string"
      }
    ],
    "createAt": "string",
    "updatedAt": "string"
  }
]
*/
export function APIGetOEMOrderItems(orderId, callback) {
    return async dispatch => {
        try {
            const url = `/api/customer/oem/order/${orderId}/item`
            let rsp = await fetch(url)
            const json = await HandleResponseJson(rsp)
            const data = json.data
            const errorMessage = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, data, errorMessage)
        } catch (err) {
            console.error("API ERROR: ", err)
            callback(false, undefined, err)
        }
    }
}

/*
Request Payload
{
  "name": "string", // Required
  "note": "string",
  "quantity": 0 // Required
}
Response
{
  "id": 0,
  "name": "string",
  "note": "string",
  "quantity": 0,
  "price": "string",
  "files,omitempty": [
    {
      "id": 0,
      "name": "string",
      "size": "string",
      "note": "string",
      "downloadLink": "string",
      "createAt": "string",
      "updatedAt": "string"
    }
  ],
  "createAt": "string",
  "updatedAt": "string"
}
*/
export function APICreateOEMOrderItem(orderId, item, callback) {
    return async dispatch => {
        try {
            const url = `/api/customer/oem/order/${orderId}/item`
            let rsp = await fetch(url, {
                method:'POST',
                body: JSON.stringify(item)
            })
            const json = await HandleResponseJson(rsp)
            const error = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, error)
        } catch(err) {
            console.error("API ERROR: ", err)
            callback(false, err)
        }
    }
}

export function APIDeleteOEMOrderItem(itemId, callback) {
    return async dispatch => {
        try {
            const url = `/api/customer/oem/order/item/${itemId}`
            let rsp = await fetch(url, {
                method:'DELETE'
            })
            const isSuccess = HandleResponseError(rsp, dispatch)
            const json = await HandleResponseJson(rsp)
            const error = json.message
            callback(isSuccess, error)
        } catch (err) {
            console.error("API ERROR: ", err)
            callback(false, err)
        }
    }
}

/*
{
  "id": 0,
  "name": "string",
  "note": "string",
  "quantity": 0,
  "price": "string",
  "files,omitempty": [
    {
      "id": 0,
      "name": "string",
      "size": "string",
      "note": "string",
      "downloadLink": "string",
      "createAt": "string",
      "updatedAt": "string"
    }
  ],
  "createAt": "string",
  "updatedAt": "string"
}
*/
export function APIGetOEMOrderItem(itemId, callback) {
    if (typeof itemId === 'undefined' || itemId === null || itemId.length === 0) {
        return async dispatch => {
            callback(false, undefined, "No Item ID")
        }
    }
    return async dispatch => {
        try {
            const url = `/api/customer/oem/order/item/${itemId}`
            let rsp = await fetch(url)
            const json = await HandleResponseJson(rsp)
            const data = json.data
            const error = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, data, error)
        } catch (err) {
            console.error("API ERROR: ", err)
            callback(false, undefined, err)
        }
    }
}

/*
Request Payload
{
  "name": "string", // Required
  "note": "string",
  "quantity": 0 // Required
}
Response
{
  "id": 0,
  "name": "string",
  "note": "string",
  "quantity": 0,
  "price": "string",
  "files,omitempty": [
    {
      "id": 0,
      "name": "string",
      "size": "string",
      "note": "string",
      "downloadLink": "string",
      "createAt": "string",
      "updatedAt": "string"
    }
  ],
  "createAt": "string",
  "updatedAt": "string"
}
*/
export function APIUpdateOEMOrderItem(itemId, item, callback) {
    return async dispatch => {
        try {
            const url = `/api/customer/oem/order/item/${itemId}`
            let rsp = await fetch(url, {
                method:'PUT',
                body: JSON.stringify(item)
            })
            const json = await HandleResponseJson(rsp)
            const data = json.data
            const error = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, data, error)
        } catch (err) {
            console.error("API ERROR: ", err)
            callback(false, undefined, err)
        }
    }
}