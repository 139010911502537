import { Partners } from "../landing/partners";
import { Categories } from "./categories"
import "./store.css";

function Store() {
  return (
    <div className="store">
      <Categories />
      <Partners />
    </div>
  )
}
  
export default Store;