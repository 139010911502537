import React, { Component } from 'react'
import './about.css'

class Warranty extends Component {
    render() {
        return (
            <div className='warrantyContainer'>
                <h2>Warranty</h2>
                <br/>
                <div>
                All McJ Tools products are warranted against material and workmanship defects. If the product is found to be defective due to material or workmanship, McJ Tools will replace / refund the defective product at no charge to the customer. Warranty claims are handled on a case-by-case basis. 

                </div>
                <br/>
                <div>
                In order to initiate a claim, please contact our Customer Service Technicians at +1 (302) 488-9969 or info@mcjtools.com
                </div>
                <br/>
                <h2>Shipping</h2>
                <br/>
                <div>
                A confirmation email will be sent to the address listed on the account upon order entry.  Additionally, an email will be sent to the same email address when the order has been shipped.

                </div>
                <br/>
                <h2>RETURNS / REFUNDS</h2>
                <br/>
                <div>
                Please contact our customer service at +1 (302) 203-9713 or info@mcjtools.com for information on product returns / refunds.
                </div>
            </div>
        )
    }
}

export default Warranty