/*
cart: [
        {
            "id": 1319,
            "productId": 512,
            "price": "46.00",
            "quantity": 1,
            "updatedAt": "2024-10-27 16:11:50"
        }
    ]
*/

export function getGuestCart() {
    return JSON.parse(localStorage.getItem('guestCart')) || []
}

export function getQuantityFromGuestCart(productId) {
    const guestCart = getGuestCart()
    const existingProduct = guestCart.find(item => item.productId === productId)
    return existingProduct ? existingProduct.quantity : 0
}

export function addToGuestCart(product, quantity, override) {
    let guestCart = getGuestCart()

    // Check for existing product in guest cart
    const existingProductIndex = guestCart.findIndex(item => item.productId === product.id)
    
    if (existingProductIndex !== -1) {
        if (override) {
            guestCart[existingProductIndex].quantity = quantity
        } else {
            guestCart[existingProductIndex].quantity += quantity
        }
    } else {
        // Add new product to cart
        guestCart.push({
            productId: product.id,
            quantity: quantity,
            price: product.price,
            product: product // Store product details for display
        })
    }

    localStorage.setItem('guestCart', JSON.stringify(guestCart))
}

export function removeFromGuestCart(productId) {
    let guestCart = getGuestCart()
    guestCart = guestCart.filter(item => item.productId !== productId)
    localStorage.setItem('guestCart', JSON.stringify(guestCart))
}

/*
[
    {
        "id": 54,
        "accountId": 2,
        "name": "Alex Brown",
        "phone": "+16793254876",
        "email": "alex.brown@yahoo.com",
        "address": "7680 Prospect Ave",
        "city": "Fort Dodge",
        "postal": "50501",
        "state": "IA",
        "country": "US",
        "createdAt": "2022-12-29 21:44:29",
        "updatedAt": "2023-09-25 23:18:22"
    }
]
*/
export function getGuestAddresses() {
    return JSON.parse(localStorage.getItem('guestAddresses')) || []
}

export function addGuestAddress(address) {
    if (address === undefined || address === null) {
        return
    }
    if (typeof address.id === 'undefined' || address.id === null) {
        address.id = new Date().getTime()
    }
    let guestAddresses = getGuestAddresses()
    guestAddresses.push(address)
    localStorage.setItem('guestAddresses', JSON.stringify(guestAddresses))
}

export function removeGuestAddress(addressId) {
    let guestAddresses = getGuestAddresses()
    guestAddresses = guestAddresses.filter(item => item.id !== addressId)
    localStorage.setItem('guestAddresses', JSON.stringify(guestAddresses))
}

export function updateGuestAddress(address) {
    let guestAddresses = getGuestAddresses()
    const existingAddressIndex = guestAddresses.findIndex(item => item.id === address.id)
    if (existingAddressIndex !== -1) {
        guestAddresses[existingAddressIndex] = address
        localStorage.setItem('guestAddresses', JSON.stringify(guestAddresses))
    }
}

export function getGuestAddress(addressId) {
    const guestAddresses = getGuestAddresses()
    return guestAddresses.find(item => item.id === addressId)
}