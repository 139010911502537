import { bindActionCreators } from '@reduxjs/toolkit'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import OEMOrderCard from './oemOrderCard'
import OEMOrderMessages from './oemOrderMessages'
import OemOrderItems from './oemOrderItems'
import {
    APIGetOEMOrder
} from '../../apis/oemOrder'
import './oemOrderDetail.css'
import { withRouter } from '../../common/withRouter'
import { Spinner } from 'react-bootstrap'

class OEMOrderDetail extends Component {

    constructor() {
        super()
        this.state = {
            isLoadingData: true,
            isShowingUpdateOrder: false,
            updateOrderId: undefined,
            updateOrderStatus: "",
            updateOrderNote: "",
            updateOrderErrorMessage: "",
            isUpdatingOrder: false
        }
    }

    componentDidMount() {
        const { id } = this.props.router.params
        this.props.APIGetOEMOrder(id, (success, data, error) => {
            this.setState({ isLoadingData: false })
        })
    }

    render() {
        const { id } = this.props.router.params
        let order = this.props.oemOrdersState.oemOrderMap?.[id]
        const orderCard = <OEMOrderCard order={order} reloadOrder={ this.props.APIGetOEMOrder }/>
        const orderMessages = <OEMOrderMessages orderId={id} />
        const orderItems = <OemOrderItems orderId={ id } />
        return (
            <div style={{ marginTop: '58px', marginBottom: '58px' }} className='container'>
                <div style={{ display: 'flex', alignItems: 'center', height: '40px', marginTop: '4px', marginBottom: '4px' }}>
                    <span style={{ fontSize: '20px', fontWeight: 'bolder' }}>
                        <Link to={`/oem`} style={{ textDecoration: 'none' }}>
                            Orders
                        </Link>
                        {` › ${id}`}
                    </span>
                </div>
                
                { this.state.isLoadingData && (
                    <div style={{ textAlign: 'center' }}>
                        <Spinner animation='border' style={{ marginTop: '80px', marginBottom: '80px'}} />
                    </div>
                )}
                { !this.state.isLoadingData && (
                    <div className='OrderDetailContainer'>
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                            { orderCard }
                            { orderItems }
                        </div>
                        { orderMessages }
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    authState: state.authState,
    oemOrdersState: state.oemOrdersState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        APIGetOEMOrder
    }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OEMOrderDetail))