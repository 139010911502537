import React, { Component } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import Logo from '../../common/logo'
import './signin.css'

class ResetPsw extends Component {
    
    constructor() {
        super()
        this.state = {
            password:"",
            passwordRP:"",
            errMsg:"",
            status:"INIT"
        }
    }

    onValueChange = e =>{
        this.setState({
            [e.target.name]:e.target.value,
            errMsg:""
        })
    }

    resetRequest = () => {
        if(this.state.password.length < 6) {
            this.setState({
                errMsg:"Minimum 6 characters required"
            })
            return
        }

        // eslint-disable-next-line eqeqeq
        if(this.state.password != this.state.passwordRP) {
            this.setState({
                errMsg:'Passwords do not match'
            })
            return
        }

        this.setState({
            status:"REQUESTING"
        })

        fetch('/api/anonymous/reset', {
            method:"PUT",
            body:JSON.stringify({
                token:this.props.token,
                password:this.state.password
            })
        }).then(res => {
            // eslint-disable-next-line eqeqeq
            if (res.status == 200) {
                this.setState({
                    status:"SUCCEED"
                })
            } else {
                this.setState({
                    status:"INIT",
                    errMsg:"Reset password error."
                })
            }
            
        }).catch(err => {
            this.setState({
                status:"INIT",
                errMsg:"Reset password request error."
            })
        })
    }

    render() {
        let requesting = false
        // eslint-disable-next-line eqeqeq
        if(this.state.status == "REQUESTING") {
            requesting = true
        }
        let content = null
        // eslint-disable-next-line eqeqeq
        if (this.state.status == "SUCCEED") {
            content = <div className='resetSucc'>
                <p>Reset password succeed.</p>
                <Link to={'/signin'}><Button variant='warning'>SignIn Now</Button></Link>
            </div>
        } else {
            content = <>
                <span>Enter your new password</span>
                    <input type={"password"} className='forgotIpt' name="password" onChange={this.onValueChange} value={this.state.password} />
                    <br/>
                    <span>Confirm new password</span>
                    <input type={"password"} className='forgotIpt' name="passwordRP" onChange={this.onValueChange} value={this.state.passwordRP} />
                    <br/>
                    <p className='errMsg'>{this.state.errMsg}</p>
                    {requesting?<><Spinner className='forgotSpinner' animation='border' /><br/></>:null}
                    <Button variant='warning' onClick={this.resetRequest}>Reset password</Button>
            </>
        }

        return (
            <div className='root'>
                <div className='top'>
                    <Logo />
                    <div className='signinBox'>
                        <h3>Reset Password</h3>
                        <hr />
                        <div className='resetForm'>
                            {content}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function ResetPassword() {
    const {token} = useParams()

    return <ResetPsw token={token} />
}

export default ResetPassword