import { createSlice } from '@reduxjs/toolkit'
import MCJ from "../common/def"

const promoSlice = createSlice({
  name: "promoSlice",
  initialState: {
      status: MCJ.STATUS_INIT,
      signUpStatus: MCJ.STATUS_INIT
  },
  reducers: {
    promoGetInfo: (state, action) => {
      state.status = action.payload.status
      state.info = action.payload.data
      state.code = action.payload.code
    },
    promoConfirm: (state, action) => {
      state.status = action.payload.status
    },
    promoSignUp: (state, action) => {
      state.signUpStatus = action.payload
    }
  }
})

export const {
  promoGetInfo,
  promoConfirm,
  promoSignUp
} = promoSlice.actions

export default promoSlice