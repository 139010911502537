import { createSlice } from '@reduxjs/toolkit'
import MCJ from "../common/def"
import countries from "../common/countries.json"

const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    userInfo: {},
    addresses: []
  },
  reducers: {
    getMe: (state, action) => {
      if (action.payload.ret) {
        state.userInfo = action.payload.data
      }
    },
    signInUser: (state, action) => {
      if (action.payload.authed) {
        state.userInfo = action.payload.data;
      }
    },
    signOutUser: (state, action) => {
      state.userInfo = undefined;
    },
    getSystem: (state, action) => {
      MCJ.DefaultImgPrefix = action.payload.imageUrlPrefix;
      const stripe = action.payload.stripe;
      if (typeof stripe !== "undefined" && stripe !== null) {
        MCJ.STRIPE_KEY = stripe;
      }
      state.system = action.payload;
      state.system.countries = countries
    },
    saveUserInfo: (state, action) => {
      console.group("saveUserInfo", action.payload)
      if (action.payload.ret) {
        state.userInfo = action.payload.data
      }
      state.error = action.payload.error
    },
    changePassword: (state, action) => {
      state.changePasswordStatus = action.payload;
    },
    getAddresses: (state, action) => {
      state.addresses = action.payload
    },
    createOrUpdateAddress: (state, action) => {
      state.createOrUpdateAddressStatus = action.payload
    }
  },
})

export const {
  getMe,
  signInUser,
  signOutUser,
  getSystem,
  saveUserInfo,
  changePassword,
  getAddresses,
  createOrUpdateAddress
} = userSlice.actions

export default userSlice