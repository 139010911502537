import { configureStore } from "@reduxjs/toolkit"
import authSlice from "./authSlice"
import cartSlice from "./cartSlice"
import prodSlice from "./prodSlice"
import promoSlice from "./promoSlice"
import userSlice from "./userSlice"
import oemOrderSlice from './oemOrderSlice'

const store = configureStore({
    reducer: {
        authState: authSlice.reducer,
        userState: userSlice.reducer,
        cartState: cartSlice.reducer,
        prodState: prodSlice.reducer,
        promoState: promoSlice.reducer,
        oemOrdersState: oemOrderSlice.reducer
    }
})

export default store