import React, { Component } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { APIGetAddresses, APICreateOrUpdateAddress } from '../../../apis/user'
import { AddressDropDown } from '../../../common/addressDropDown'
import MCJ from '../../../common/def';
import './editAddress.css'

class EditAddress extends Component {

    constructor() {
        super()
        this.state = {
            isSavingChanges: false,
            errorMessage: null
        }
    }

    getEditingAddress = () => {
        const addressId = this.props.addressId
        var address = null
        if (typeof addressId !== 'undefined' && addressId !== null) {
            const { addresses } = this.props.userState
            if (typeof addresses !== 'undefined' && addresses !== null) {
                // eslint-disable-next-line eqeqeq
                const filteredAddresses = addresses.filter(adrs => adrs.id == addressId)
                if (filteredAddresses.length > 0) {
                    address = filteredAddresses[0]
                }
            }
        }
        if (address === null) {
            address = {
                country: "US"
            }
        }
        return address
    }

    async componentDidMount() {
        await this.props.APIGetAddresses()
        this.setState(preState => {
            return {
                ...preState,
                editingAddress: this.getEditingAddress()
            }
        })
    }

    handleEditChanges = e =>{
        let target = e.target
        if (typeof this.state.editingAddress === 'undefined') {
            let newEditingAddress = {
                ...this.getEditingAddress(),
                [target.name]:target.value
            }
            this.setState(preState => {
                return {
                    ...preState,
                    editingAddress: newEditingAddress
                }
            })
        } else {
            this.setState(preState => {
                return {
                    ...preState,
                    editingAddress: {
                        ...preState.editingAddress,
                        [target.name]: target.value
                    }
                }
            })
        }   
    }

    getEditValue = name => {
        if (typeof this.state.editingAddress !== 'undefined') {
            return this.state.editingAddress[name]
        } else {
            return ""
        }
    }

    saveChanges = async () => {
        if (typeof this.state.editingAddress !== 'undefined') {
            let address = Object.assign({}, this.state.editingAddress)
            this.setState({ isSavingChanges: true })
            await this.props.APICreateOrUpdateAddress(address)
            this.setState({ isSavingChanges: false })
            if (this.props.userState.createOrUpdateAddressStatus.status !== MCJ.STATUS_LOAD_OK) {
                this.setState({ errorMessage:  this.props.userState.createOrUpdateAddressStatus.errorMessage})
            } else {
                window.open('/user/addresses', '_self')
            }
        }
    }

    renderEditItem = (text, name) => {
        return (
            <tr key={name}>
                <td className='keyCol'><span>{text}</span></td>
                <td><input name={name} className='editInput' onChange={this.handleEditChanges} value={this.getEditValue(name)} /></td>
            </tr>
        )
    }

    renderAddress = () => {
        let city = this.renderEditItem('City', 'city')
        let name = this.renderEditItem('Name', 'name')
        let address = this.renderEditItem('Address', 'address')
        let postal = this.renderEditItem('Postal', 'postal')
        let state = this.renderEditItem('State', 'state')
        let country = this.renderEditItem('Country', 'country')
        let phone = this.renderEditItem('Phone', 'phone')
        let email = this.renderEditItem('Email', 'email')
        let error = (
            <tr key='error'>
                <td className='keyCol'>
                    <span></span>
                </td>
                <td>
                    <p className='errorMessage editInput'>{this.state.errorMessage}</p>
                </td>
            </tr>
        )
        const system = this.props.userState.system
        if (typeof system !== 'undefined' && system !== null) {
            let currCountry = this.getEditValue('country')
            if (typeof currCountry === 'undefined' || currCountry === null || currCountry === "") {
                currCountry = "US"
                this.handleEditChanges({ target: { name: 'country', value: 'US' } })
            }
            country = (
                <tr key='country'>
                    <td style={{ width: '150px', textAlign: 'right' }}><span>Country</span></td>
                    <td>
                        <div className='dropdownList'>
                            <AddressDropDown choiceKey='country' choiceValue={currCountry} choices={system.countries} onClick={(e) => {
                                this.handleEditChanges(e)
                            }} />
                        </div>
                    </td>
                </tr>
            )
            const states = (system.countries.find(c => c.abbrev.toLowerCase() === currCountry.toLowerCase()) || { states: [] }).states
            let currState = this.getEditValue('state')
            state = (
                <tr key='state'>
                    <td style={{ width: '150px', textAlign: 'right' }}><span>State</span></td>
                    <td>
                        <div className='dropdownList'>
                            <AddressDropDown choiceKey='state' choiceValue={currState} choices={states} onClick={(e) => {
                                this.handleEditChanges(e)
                            }} />
                        </div>
                    </td>
                </tr>
            )
        }
        return <table>
            <tbody>
                {name}
                {phone}
                {email}
                {address}
                {city}
                {postal}
                {country}
                {state}
                {error}
            </tbody>
        </table>
    }

    render() {
        const addressId = this.props.addressId
        const isNewAddress = !(typeof addressId !== 'undefined' && addressId !== null)
        let address = this.renderAddress()
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginTop: '16px', marginBottom: '16px' }}>
                    <h3>{isNewAddress ? 'Add a new address': 'Edit your address'}</h3>
                    <hr/>
                    {address}
                </div>
                <div>
                    <hr/>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {this.state.isSavingChanges === true && <Spinner className='spinner-border-sm' animation='border' style={{ alignSelf: 'center', marginRight: '8px', width: '20px', height: '20px' }} />}
                        <Button disabled={this.state.isSavingChanges === true} variant='warning' onClick={this.saveChanges} >
                        {isNewAddress ? 'Add Address': 'Save Changes'}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userState: state.userState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        APIGetAddresses,
        APICreateOrUpdateAddress
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAddress)