import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {APIGetOrders, APIConfirmOrder} from '../../../apis/cart'
import MCJ from '../../../common/def';
import { Spinner } from 'react-bootstrap'
import OrderCard from './orderCard';

class UserOrders extends Component {

    constructor() {
        super()
        this.state = {
            status:MCJ.STATUS_LOADING,
        }
    }

    async componentDidMount() {
        await this.props.APIGetOrders()
        this.setState({ status:MCJ.STATUS_OK })
    }

    renderOrders = () => {
        const {orderList} = this.props.cartState
        
        let content = orderList.map(order => {   
            return <OrderCard key={order.id} order={order} />
        })
        return content
    }


    render(){
        let content = this.renderOrders()
        if (content.length === 0) {
            if (this.state.status === MCJ.STATUS_LOADING) {
                content = (
                    <div style={{ textAlign: 'center' }}>
                        <Spinner animation='border' style={{ marginTop: '32px' }} />
                    </div>
                )
            } else {
                content = (
                    <div>
                        <h4 style={{ marginTop: '32px', color: 'GrayText' }}>You don't have any order yet.</h4>    
                    </div>
                )
            }
        }
        return (
            <div>
                <h2>Your Orders</h2>
                <hr/>
                {content}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    cartState:state.cartState,
    prodState:state.prodState,
    userState:state.userState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        APIGetOrders,
        APIConfirmOrder
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserOrders)