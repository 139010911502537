import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from '../../../common/withRouter'

class MenuAccount extends Component {

    render() {
        var region = this.props.router.params.region || ""
        if (region.length > 0) {
            region = `/${region}`
        }
        const { authed } = this.props.authState
        return (
            <a className="nav-link" href={authed ? `${region}/user/account` : `${region}/signin`}>
                <i className="fa fa-user me-2"></i>
                Account
            </a>
        )
    }
}

const mapStateToProps = state => ({
    authState: state.authState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
    }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuAccount))