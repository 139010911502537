import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { APIGetProductDetail } from '../../apis/products'
import { withRouter } from '../../common/withRouter'

class Spotlights extends Component {

    constructor() {
        super()
        this.state = {
            list: [
                {
                  "img": "/images/newarrivals/newarrivals_1.jpg",
                  "title": 'MCJ TOOLS BRONZE CHAMBER BRUSHE 10PACK 5.56MM/.223CAL',
                  "desc": 'Tapered bronze bristles scrub the chamber, neck and shoulder. Brass stem is cold welded to the core, and the tip is looped.',
                  "url": "product/253",
                  "id": 253
                },
                {
                  "img": "/images/newarrivals/newarrivals_2.jpg",
                  "title": "MCJ TOOLS BORESNAKE 2PACK , 9MM, .357, .38CAL",
                  "desc": 'Easy and efficient: clean up in 1 quick pass. Strong and Reusable: Sturdy, machine washable. Compact and Lightweight: fits easly into your pocket.',
                  "url": "product/249",
                  "id": 249
                },
                {
                  "img": "/images/newarrivals/newarrivals_3.jpg",
                  "title": '7/8" TCT Hole Saw Long for Stainless Steel Cutting, Thick Metal Cutting',
                  "desc": 'Designed for use in a chuck - can be used with drill press or a hand drill.',
                  "url": "product/197",
                  "id": 197
                },
                {
                  "img": "/images/newarrivals/newarrivals_4.jpg",
                  "title": 'PRIMER POCKET REAMER SET (SMALL AND LARGE)',
                  "desc": 'The perfect solution for quickly removing the military crimp from primer pockets.',
                  "url": "product/49",
                  "id": 49
                },
                {
                  "img": "/images/newarrivals/newarrivals_5.jpg",
                  "title": 'MCJ TOOLS BORESNAKE 2PACK , .223, .22, 5.56MM',
                  "desc": 'Easy and efficient: clean up in 1 quick pass. Strong and Reusable: Sturdy, machine washable. Compact and Lightweight: fits easly into your pocket.',
                  "url": "product/250",
                  "id": 250
                },
                {
                  "img": "/images/newarrivals/newarrivals_6.jpg",
                  "title": 'AUTO PRIMER FEEDER TUBE COMBO (3 LARGE + 3 SMALL)',
                  "desc": 'Stops misfires as greasy fingers never need to touch primers.',
                  "url": "product/63",
                  "id": 63
                }
              ].sort(() => 0.5 - Math.random()).splice(3)
        }
    }

    componentDidMount() {
        this.state.list.forEach((item) => {
            this.props.APIGetProductDetail(this.props.authState.authed, item.id)
        })
    }

    render() {
        var region = this.props.router.params.region || ""
        if (region.length > 0) {
            region = `/${region}`
        }
        const { productDetailsById } = this.props
        return (
            <main id="spotlights" className="text-center">
                <div className="container">
                    <div className='section-title'>
                        <h2>Spotlights</h2>
                    </div>
                </div>
                <div className="container d-flex justify-content-between mt-5">
                    <div className="container overflow-visible">
                        <div className="row">
                            {
                                this.state.list.map((item, i) => {
                                    let price = undefined
                                    let product = productDetailsById[item.id]
                                    if (typeof product !== 'undefined' && product !== null) {
                                        price = product.price
                                    }
                                    return (
                                        <div key={`${i}.${item.name}`}  className="col-lg-4 col-md-6 col-sm-6" style={{ marginTop: '24px'}}>
                                            <div className="card prod-card h-100 pb-3 shadow">
                                                <img src={item.img} className="card-img-top img-fluid" alt={item.name} />
                                                <div className="card-body">
                                                    <h4 className="card-title">{item.title}</h4>
                                                    <p className="card-text">
                                                        {item.desc}
                                                    </p>
                                                </div>
                                                { (region !== "/au" && region !== "/nz") && (
                                                    <div className="card-footer card-bottom d-flex justify-content-between">
                                                        <h3 className="price">${price !== undefined ? price : '--.--'}</h3>
                                                        <a href={`${region}/${item.url}`} className="btn btn-warning">
                                                            <i className="fa fa-shopping-cart me-2"></i>
                                                            Buy Now
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}


const mapStateToProps = state => ({
    authState: state.authState,
    productDetailsById: state.prodState.productDetailsById
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
      APIGetProductDetail
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Spotlights))
