import { bindActionCreators } from '@reduxjs/toolkit'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    APIGetOEMOrderItems,
    // APIGetOEMOrderItem,
    APICreateOEMOrderItem,
    APIDeleteOEMOrderItem,
    APIUpdateOEMOrderItem,
} from '../../apis/oemOrderItem'
import {
    APIGetOEMOrderItemFiles,
    // APIGetOEMOrderItemFile,
    APICreateOEMOrderItemFile,
    APIUpdateOEMOrderItemFile,
    APIDeleteOEMOrderItemFile
} from '../../apis/oemOrderItemFile'
import './oemOrderItems.css'
import moment from 'moment/moment'
import { Button, Modal, Spinner } from 'react-bootstrap'
import prettyBytes from 'pretty-bytes'
import { withRouter } from '../../common/withRouter'


class OEMOrderItems extends Component {

    constructor() {
        super()
        this.state = {
            items: [],
            // Edit Item
            isShowingEditModal: false,
            editItem: null,
            editItemError: null,
            // Delete Item
            isShowingDeleteItemModal: false,
            deleteItem: null,
            deleteItemError: null,
            // Upload File
            isShowingUploadFileModal: false,
            isUploadingFile: false,
            uploadFileItemId: null,
            uploadFile: null,
            uploadFileNote: null,
            uploadFileError: null,
            // Edit File
            isShowingEditFileModal: false,
            editFile: null,
            editFileNote: null,
            editFileError: null
        }
    }

    componentDidMount() {
        this.loadItems()
    }

    onValueChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onValueChangeForEditItem = e => {
        this.setState(preState => {
            return {
                ...preState,
                editItem: {
                    ...preState.editItem,
                    [e.target.name]:e.target.value
                }
            }
        })
    }

    loadItems() {
        const { orderId } = this.props
        this.props.APIGetOEMOrderItems(orderId, (success, items, error) => {
            this.setState({ items: items })
            for (let index in items) {
                this.props.APIGetOEMOrderItemFiles(items[index].id, (success, files, error) => {
                    this.setState(preState => {
                        let newItems = preState.items
                        for (let index2 in newItems) {
                            if (newItems[index2].id === items[index].id) {
                                newItems[index2].files = files
                            }
                        }
                        return {
                            ...preState,
                            items: newItems
                        }
                    })
                })
            }
        })
    }

    editItem() {
        this.props.APIUpdateOEMOrderItem(
            this.state.editItem.id,
            this.state.editItem,
            (success, item, error) => {
                this.setState({ editItemError: error })
                if (success) {
                    this.setState({ isShowingEditModal: false })
                    this.loadItems()
                }
        })
    }

    deleteItem(itemId) {
        this.props.APIDeleteOEMOrderItem(
            itemId,
            (success, error) => {
                this.setState({ deleteItemError: error })
                if (success) {
                    this.setState({ isShowingDeleteItemModal: false })
                    this.loadItems()
                }
            })
    }

    createItem() {
        this.props.APICreateOEMOrderItem(
            this.props.orderId,
            this.state.editItem,
             (success, error) => {
                if (success) {
                    this.setState({ isShowingEditModal: false })
                    this.loadItems()
                } else {
                    this.setState({ editItemError: error })
                }
        })
    }

    formatItemSenderString = (sender) => {
        let result = sender.name
        if (typeof sender.company !== 'undefined' && sender.company !== null && sender.company.length > 0) {
            result += ` (${sender.company})`
        }
        return result
    }

    formatItemDateString = (dateString) => {
        if (typeof dateString === 'undefined' || dateString === null) {
            return ""
        }
        const mnt = moment.utc(dateString).local()
        return mnt.format('LLL')
    }

    renderItemActions(item) {
        return <div style={{ display: 'flex' }}>
            <div style={{ flexGrow: 1 }} />
            <Button
                className='CommonButton Transparent shadow-none'
                style={{
                    fontSize: '11px',
                    textDecoration: 'underline',
                    marginRight: '4px'
                }}
                variant="warning"
                onClick={ () => {
                    this.setState({
                        deleteItem: item,
                        deleteItemError: "",
                        isShowingDeleteItemModal: true
                    })
                } }>
                Delete
            </Button>
            <Button
                className='CommonButton Transparent shadow-none'
                style={{
                    fontSize: '11px',
                    textDecoration: 'underline',
                    marginRight: '4px'
                }}
                variant="warning"
                onClick={ () => {
                    this.setState({
                        editItem: item,
                        editItemError: "",
                        isShowingEditModal: true
                    })
                } }>
                Edit
            </Button>
        </div>
    }

    renderItemFiles(item, showActions) {
        const files = item.files || []
        return (
            <div className='OrderItemFiles'>
                <div className='OrderItemFilesHeader'>
                    <span style={{ marginLeft: '4px' }}>
                        Attachments
                    </span>
                    <div style={{ flexGrow: 1 }} />
                    { showActions && (
                        <Button
                            className='CommonButton Transparent shadow-none'
                            style={{
                                fontSize: '11px',
                                textDecoration: 'underline',
                                marginRight: '4px',
                                color: 'white'
                            }}
                            onClick={ () => {
                                this.setState({
                                    isShowingUploadFileModal: true,
                                    uploadFileItemId: item.id,
                                    uploadFile: null,
                                    uploadFileError: null
                                })
                            } }>
                            Upload
                        </Button>
                    )}
                </div>
                <div style={{ backgroundColor: 'lightgray' }}>
                    <div className='OrderItemFilesList'>
                        { files.length === 0 && (
                            <span style={{ textAlign: 'center', color: 'gray' }}>
                                Empty
                            </span>
                        )}
                        { files.length > 0 && (
                            files.map( file => {
                                return (
                                    <div key={file.id} className='OrderItemFilesListItem'>
                                        <div className='OrderItemFilesListItemFile'>
                                            <a className='OrderItemFilesListItemLink'
                                                href={file.downloadLink}>
                                                {file.name}
                                            </a>
                                            <div style={{ flexGrow: 1 }} />
                                            <span className='OrderItemFilesListItemFileSize'>
                                                {prettyBytes(Number(file.size))}
                                            </span>
                                        </div>
                                        <span className='OrderItemFilesListItemNote'>
                                            {file.note}
                                        </span>
                                        <div className='OrderItemFilesListItemFooter'>
                                            <span>{this.formatItemDateString(file.updatedAt)}</span>
                                            <div style={{ flexGrow: 1 }} />
                                            { showActions && (
                                                <Button
                                                    className='CommonButton Transparent shadow-none'
                                                    style={{
                                                        fontSize: '10px',
                                                        textDecoration: 'underline',
                                                        color: 'black'
                                                    }}
                                                    onClick={ () => {
                                                        this.setState({
                                                            editFile: file,
                                                            editFileError: null,
                                                            isShowingEditFileModal: true
                                                        })
                                                    } }>
                                                    Edit
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                )
                            })
                        )}
                    </div>
                </div>
            </div>
        )
    }

    renderItem(item, showActions=true) {
        if (typeof item === 'undefined' || item === null) {
            return null
        }
        return (
            <div key={item.id} className='OrderItem'>
                <div className='OrderItemHeader'>
                    <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {item.name}
                    </span>
                    <div style={{ flexGrow: 1 }}></div>
                    <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        Quantity: {item.quantity}
                    </span>
                </div>
                <div className='OrderItemBody'>
                    <span>{item.note}</span>
                </div>
                <div className='OrderItemFooter'>
                    <span style={{
                        flexGrow: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        marginRight: '4px',
                        color: 'gray'
                    }}>
                        {`${this.formatItemDateString(item.updatedAt)}`}
                    </span>
                    { showActions && this.renderItemActions(item)}
                </div>
                { this.renderItemFiles(item, showActions) }
            </div>
        )
    }

    renderEditItemField = (title, key, isRequired) => {
        return (
            <div style={{ fontSize: '14px', display: 'flex', marginBottom: '8px', alignItems: 'center' }}>
                <label style={{ textAlign: 'right', width: '100px', marginRight: '8px', fontWeight: 500 }}>
                    {title}
                    {isRequired && <span style={{ color: 'red' }}>&nbsp;*</span>}
                </label>
                <input style={{ flexGrow: 1 }} type="text" id={key} name={key} defaultValue={this.state.editItem?.[key]} onChange={this.onValueChangeForEditItem} />
            </div>
        )
    }

    renderEditItemModal = () => {
        const isNew = this.state.editItem?.id === undefined
        return (
            <Modal size='md' show={ this.state.isShowingEditModal } onHide={ () => { this.setState({ isShowingEditModal: false }) } }>
                <div className='CommonDialogHeader'>
                    <h3 className='CommonDialogTitle'>{ isNew ? 'Add New Item' : `Edit Item ${this.state.editFile?.id}` } </h3>
                </div>
                <hr style={{ margin: '0px' }}/>
                <Modal.Body>
                <form>
                    { this.renderEditItemField('Name', 'name', true) }
                    { this.renderEditItemField('Note', 'note') }
                    { this.renderEditItemField('Quantity', 'quantity', true) }
                </form>
                <span className='CommonErrorMessage'>{this.state.editItemError}</span>
                </Modal.Body>
                <Modal.Footer className='CommonDialogFooter'>
                    <Button
                        className='CommonButton shadow-none'
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px',
                        }}
                        variant="secondary"
                        onClick={ () => { this.setState({ isShowingEditModal: false }) } }>
                        CANCEL
                    </Button>
                    <div style={{ flexGrow: 1 }}/>
                    <Button
                        className='CommonButton shadow-none'
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px'
                        }}
                        variant="warning"
                        disabled={ this.state.isUpdatingCategory}
                        onClick={ async () => {
                            isNew ? this.createItem() : this.editItem()
                        }
                        }>
                        { isNew ? 'ADD' : 'SAVE' }
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderDeleteItemModal = () => {
        const deleteItem = this.state.deleteItem
        const deleteItemId = deleteItem?.id
        return (
            <Modal size='md' show={ this.state.isShowingDeleteItemModal } onHide={ () => { this.setState({ isShowingDeleteItemModal: false }) } }>
                <div className='CommonDialogHeader'>
                    <h3 className='CommonDialogTitle'>Delete Item?</h3>
                </div>
                <hr style={{ margin: '0px' }}/>
                <Modal.Body>
                {this.renderItem(deleteItem, false)}
                <span className='CommonErrorMessage'>{this.state.deleteItemError}</span>
                </Modal.Body>
                <Modal.Footer className='CommonDialogFooter'>
                    <Button
                        className='CommonButton shadow-none'
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px',
                        }}
                        variant="secondary"
                        onClick={ () => { this.setState({ isShowingDeleteItemModal: false }) } }>
                        CANCEL
                    </Button>
                    <div style={{ flexGrow: 1 }} />
                    <Button
                        className='CommonButton shadow-none'
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px'
                        }}
                        variant="danger"
                        disabled={ this.state.isUpdatingCategory}
                        onClick={ async () => {
                            this.deleteItem(deleteItemId)
                        }
                        }>
                        DELETE
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderUploadFileModal = () => {
        return (
            <Modal size='md' show={ this.state.isShowingUploadFileModal } onHide={ () => { this.setState({ isShowingUploadFileModal: false }) } }>
                <div className='CommonDialogHeader'>
                    <h3 className='CommonDialogTitle'>
                        Upload Attachment
                    </h3>
                </div>
                <hr style={{ margin: '0px' }}/>
                <Modal.Body>
                <form>
                    <div className="mb-3">
                        <label className="col-form-label">Pick a file</label>
                        <input
                            type="file"
                            className="form-control form-control-sm"
                            id="file"
                            onChange={ e => {
                                this.setState({ uploadFile: e.target.files[0] })
                            }} />   
                    </div>
                    <div className="mb-3">
                        <label className="col-form-label">Note</label>
                        <textarea
                            className="form-control form-control-sm"
                            onChange={ e => {
                                this.setState({ uploadFileNote: e.target.value })
                            }} />
                    </div>
                </form>
                <span className='CommonErrorMessage'>{this.state.uploadFileError}</span>
                </Modal.Body>
                <Modal.Footer className='CommonDialogFooter'>
                    <Button
                        className='CommonButton shadow-none'
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px',
                        }}
                        variant="secondary"
                        disabled={ this.state.isUploadingFile }
                        onClick={ () => { this.setState({ isShowingUploadFileModal: false }) } }>
                        CANCEL
                    </Button>
                    <div style={{ flexGrow: 1 }} />
                    {this.state.isUploadingFile === true && <Spinner animation='border' style={{ width: '24px', height: '24px', marginRight: '8px'}} />}
                    <Button
                        className='CommonButton shadow-none'
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px'
                        }}
                        variant="warning"
                        disabled={ this.state.isUploadingFile || this.state.uploadFile === null }
                        onClick={ async () => {
                            await this.setState({ isUploadingFile: true })
                            this.props.APICreateOEMOrderItemFile(
                                this.state.uploadFileItemId,
                                this.state.uploadFile,
                                this.state.uploadFileNote,
                                (success, error) => {
                                    if (success) {
                                        this.loadItems()
                                        this.setState({
                                            isUploadingFile: false,
                                            isShowingUploadFileModal: false
                                        })
                                    } else {
                                        this.setState({
                                            isUploadingFile: false,
                                            uploadFileError: error
                                        })
                                    }
                                }
                            )
                        }}>
                        UPLOAD
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderEditFileModal = () => {
        return (
            <Modal size='md' show={ this.state.isShowingEditFileModal } onHide={ () => { this.setState({ isShowingEditFileModal: false }) } }>
                <div className='CommonDialogHeader'>
                    <h3 className='CommonDialogTitle'>
                        Edit Attachment
                    </h3>
                </div>
                <hr style={{ margin: '0px' }}/>
                <Modal.Body>
                <form>
                    <div className="mb-3" style={{ display: 'flex', flexDirection: 'column' }}>
                        <label className="col-form-label">File</label>
                        <label className="col-form-label form-control-sm">
                            {this.state.editFile?.name}
                        </label>
                    </div>
                    <div className="mb-3">
                        <label className="col-form-label">Note</label>
                        <textarea
                            className="form-control form-control-sm"
                            defaultValue={ this.state.editFile?.note }
                            onChange={ e => {
                                this.setState({ editFileNote: e.target.value })
                            }} />
                    </div>
                </form>
                <span className='CommonErrorMessage'>{this.state.editFileError}</span>
                </Modal.Body>
                <Modal.Footer className='CommonDialogFooter'>
                    <Button
                        className='CommonButton shadow-none'
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px',
                        }}
                        variant="secondary"
                        onClick={ () => { this.setState({ isShowingEditFileModal: false }) } }>
                        CANCEL
                    </Button>
                    <div style={{ flexGrow: 1 }} />
                    <Button
                        className='CommonButton shadow-none'
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px'
                        }}
                        variant="danger"
                        onClick={ () => {
                            this.props.APIDeleteOEMOrderItemFile(
                                this.state.editFile.id,
                                (success, error) => {
                                    if (success) {
                                        this.setState({ isShowingEditFileModal: false })
                                        this.loadItems()
                                    } else {
                                        this.setState({ editFileError: error })
                                    }
                                })
                        } }>
                        DELETE
                    </Button>
                    <Button
                        className='CommonButton shadow-none'
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px'
                        }}
                        variant="warning"
                        onClick={ async () => {
                            this.props.APIUpdateOEMOrderItemFile(
                                this.state.editFile.id,
                                this.state.editFileNote,
                                (success, file, error) => {
                                    if (success) {
                                        this.loadItems()
                                        this.setState({ isShowingEditFileModal: false })
                                    } else {
                                        this.setState({ editFileError: error })
                                    }
                                }
                            )
                        }}>
                        UPDATE
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        return (
            <div className='OrderItemsContainer'>
                <div className='OrderItemTitle'>
                    <span style={{ color: 'white' }}>Items ({(this.state.items ?? []).length})</span>
                    <div style={{ flexGrow: 1 }}/>
                    <Button
                        variant='warning'
                        className='CommonButton shadow-none'
                        style={{
                            backgroundColor: '#FFFFFF30',
                            borderColor: '#00000000',
                            color: '#fff',
                            marginTop: '-2px',
                            height: '20px',
                            fontSize: '12px'
                        }}
                        onClick={(e) => {
                            this.setState({
                                isShowingEditModal: true,
                                editItem: {},
                                editItemError: ""
                            })
                        }}>
                        ADD
                    </Button>
                </div>
                <div className='OrderItems'>
                    {
                        (this.state.items ?? []).map( (item) => {
                            return this.renderItem(item)
                        })
                    }
                    { (this.state.items || []).length === 0 && (
                        <h3  style={{ textAlign: 'center', marginTop: '40px', marginBottom: '40px', color: 'grey' }}>
                            Empty
                        </h3>
                    )}
                </div>
                {this.renderEditItemModal()}
                {this.renderDeleteItemModal()}
                {this.renderUploadFileModal()}
                {this.renderEditFileModal()}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    authState: state.authState,
    ordersState: state.ordersState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        APIGetOEMOrderItems,
        // APIGetOEMOrderItem,
        APICreateOEMOrderItem,
        APIDeleteOEMOrderItem,
        APIUpdateOEMOrderItem,
        APIGetOEMOrderItemFiles,
        // APIGetOEMOrderItemFile,
        APICreateOEMOrderItemFile,
        APIUpdateOEMOrderItemFile,
        APIDeleteOEMOrderItemFile
    }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OEMOrderItems))
