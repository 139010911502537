import { HandleResponseJson, HandleResponseError } from "./api"

export function APIGetDistributors(callback) {
    return async dispatch => {
        try {
            const url = `/api/anonymous/distributor`
            let rsp = await fetch(url)
            const json = await HandleResponseJson(rsp)
            const distributors = json.data
            const error = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, distributors, error)
        } catch(err) {
            console.error("API ERROR: ", err)
            callback(false, null, err)
        }
    }
}

export function APISendMessage(email, name, request, message, callback) {
    return async dispatch => {
        try {
            const url = `/api/anonymous/message`
            let rsp = await fetch(url, {
                method: 'POST',
                body: JSON.stringify({
                    email,
                    name,
                    request,
                    message
                })
            })
            const json = await HandleResponseJson(rsp)
            const error = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, error)
        } catch(err) {
            console.error("API ERROR: ", err)
            callback(false, err)
        }
    }
}