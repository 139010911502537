import { bindActionCreators } from '@reduxjs/toolkit'
import React, { Component } from 'react'
import {
    APICreateOEMOrder,
    APIGetOEMOrders
} from '../../apis/oemOrder'
import { connect } from 'react-redux'
import './oemOrderList.css'
import { Button, Modal, Spinner } from 'react-bootstrap'
import {
    OrderStatusFGColor, OrderStatusBGColor
} from './oemOrder'
import moment from 'moment'
import { withRouter } from '../../common/withRouter'

class OEMOrderList extends Component {

    constructor() {
        super()
        this.state = {
            isLoadingPage: true,
            isShowingCreateModal: false,
            createOEMOrder: {
                name: null,
                description: null
            },
            isCreatingOEMOrder: false,
            createOEMOrderError: null
        }
    }

    componentDidMount() {
        this.loadData()
    }

    loadData = async () => {
        this.props.APIGetOEMOrders((success, data, error) => {
            this.setState({ isLoadingPage: false })
        })
    }

    renderEmptyMessage = () => {
        if (this.state.isLoadingPage) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <Spinner animation='border' style={{ marginTop: '80px', marginBottom: '80px'}} />
                </div>
            )
        } else {
            return (
                <div style={{ textAlign: 'center' }}>
                    <h3  style={{ marginTop: '80px', marginBottom: '80px', color: 'grey' }}>Please create your first OEM order.</h3>  
                </div>
            )
        }
    }

    renderHeader = () => {
        return (
            <div className='CommonListHead'>
                <span className='OrderListID'>ID</span>
                <span className='OrderListName'>Name</span>
                <span className='OrderListDesc'>Description</span>
                <span className='OrderListDate'>Date</span>
                <span className='OrderListStatus'>Status</span>
            </div>
        )
    }

    renderOrder = (order) => {
        const mnt = moment.utc(order.updatedAt).local()
        return (
            <div key={order.id}
                className='CommonListItem'
                onClick={ (e) => {
                    if (['a', 'button'].includes(e.target.nodeName.toLowerCase())) {
                        return
                    } else {
                        this.props.router.navigate(`${order.id}`)
                    }
                }}>
                <span className='OrderListID'>{order.id}</span>
                <span className='OrderListName'>{order.name}</span>
                <span className='OrderListDesc'>{order.description}</span>
                <span className='OrderListDate'>{mnt.format('lll')}</span>
                <span className='OrderListStatus'>
                    <span className='CommonListTag' style={{
                        color: OrderStatusFGColor(order.status),
                        backgroundColor: OrderStatusBGColor(order.status)
                    }}>
                        {order.status}
                    </span>
                </span>
            </div>
        )
    }

    renderOrders = () => {
        const { oemOrderList } = this.props.oemOrdersState
        if (typeof oemOrderList === 'undefined' || oemOrderList === null || oemOrderList.length === 0) {
            return this.renderEmptyMessage()
        }
        return oemOrderList.map(this.renderOrder)
    }

    // Create OEM Order
    onValueChange = e => {
        this.setState(preState => {
            return {
                ...preState,
                createOEMOrder: {
                    ...preState.createOEMOrder,
                    [e.target.name]:e.target.value
                }
            }
        })
    }
    
    createOEMOrder = () => {
        this.setState({ isCreatingOEMOrder: true })
        this.props.APICreateOEMOrder(
            this.state.createOEMOrder.name,
            this.state.createOEMOrder.description,
            (success, data, error) => {
                if (success) {
                    this.setState({
                        isShowingCreateModal: false,
                        isCreatingOEMOrder: false
                    })
                    this.loadData()
                } else {
                    this.setState({
                        createUserError: error,
                        isCreatingOEMOrder: false
                    })
                }
            }
        )
    }

    renderCreateModal = () => {
        return (
            <Modal size='md' show={ this.state.isShowingCreateModal } onHide={ () => { this.setState({ isShowingCreateModal: false }) } }>
                <div className='CommonDialogHeader'>
                    <h3 className='CommonDialogTitle'>Create OEM Order</h3>
                </div>
                <hr style={{ margin: '0px' }}/>
                <Modal.Body>
                    <form>
                        <div className="mb-1">
                            <label className="col-form-label">Name</label>
                            <input type="text" className="form-control" id="email" name='name' onChange={this.onValueChange} />
                        </div>
                        <div className="mb-1">
                            <label className="col-form-label">Description</label>
                            <textarea type="text" className="form-control" id="password" name='description' onChange={this.onValueChange} />
                        </div>
                    </form>
                    <span className='CommonErrorMessage'>{this.state.createUserError}</span>
                </Modal.Body>
                <Modal.Footer className='CommonDialogFooter'>
                    <Button
                        className='CommonButton shadow-none'
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px',
                        }}
                        variant="secondary"
                        onClick={ () => { this.setState({ isShowingCreateModal: false }) } }>
                        CLOSE
                    </Button>
                    <div style={{ flexGrow: 1 }}/>
                    {this.state.isCreatingOEMOrder === true && <Spinner animation='border' style={{ alignSelf: 'center', marginRight: '16px'}} />}
                    <Button
                        className='CommonButton shadow-none'
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px',
                        }}
                        variant="warning"
                        onClick={ this.createOEMOrder }>
                        CREATE
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        const titleBar = (
            <div style={{ display: 'flex', alignItems: 'center', height: '40px', marginTop: '4px', marginBottom: '4px' }}>
                <span style={{ fontSize: '20px', fontWeight: 'bolder' }}>My OEM Orders</span>
                <div style={{ flexGrow: 1 }}/>
                <Button
                    className='CommonButton shadow-none'
                    style={{
                        height: '24px',
                        fontSize: '12px',
                    }}
                    variant='warning'
                    onClick={ () => {
                        this.setState({
                            isShowingCreateModal: true,
                            createOEMOrder: {},
                            createOEMOrderError: null
                        })
                    } } >
                    Create
                </Button>
            </div>
        )
        let orderListView = this.renderOrders()
        let orderHeadView = this.renderHeader()
        return (
            <div className='OrderListContainer container'>
                {titleBar}
                <div className='CommonList'>
                    {orderHeadView}
                    {orderListView}
                </div>
                { this.renderCreateModal() }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    oemOrdersState: state.oemOrdersState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        APIGetOEMOrders,
        APICreateOEMOrder
    }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OEMOrderList))
