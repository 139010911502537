import { HandleResponseJson, HandleResponseError  } from './api'

/*
[
  {
    "id": 0,
    "messageId": 0,
    "body": "string",
    "sender": {
      "id": 0,
      "name": "string",
      "company": "string"
    },
    "files,omitempty": [
      {
        "id": 0,
        "name": "string",
        "size": "string",
        "note": "string",
        "downloadLink": "string",
        "createAt": "string",
        "updatedAt": "string"
      }
    ],
    "createAt": "string",
    "updatedAt": "string"
  }
]
*/
export function APIGetOEMOrderMessages(orderId, callback) {
    return async dispatch => {
        try {
            const url = `/api/customer/oem/order/${orderId}/message`
            let rsp = await fetch(url)
            const json = await HandleResponseJson(rsp)
            const data = json.data
            const errorMessage = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, data, errorMessage)
        } catch (err) {
            console.error("API ERROR: ", err)
            callback(false, undefined, err)
        }
    }
}

/*
Request Payload
{
  "messageId": 0,
  "body": "string" // Required
}
Response
{
  "id": 0,
  "messageId": 0,
  "body": "string",
  "sender": {
    "id": 0,
    "name": "string",
    "company": "string"
  },
  "files,omitempty": [
    {
      "id": 0,
      "name": "string",
      "size": "string",
      "note": "string",
      "downloadLink": "string",
      "createAt": "string",
      "updatedAt": "string"
    }
  ],
  "createAt": "string",
  "updatedAt": "string"
}
*/
export function APICreateOEMOrderMessage(orderId, messageId, body, callback) {
    return async dispatch => {
        try {
            const url = `/api/customer/oem/order/${orderId}/message`
            let rsp = await fetch(url, {
                method:'POST',
                body: JSON.stringify({
                  messageId,
                  body
                })
            })
            const json = await HandleResponseJson(rsp)
            const error = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, error)
        } catch(err) {
            console.error("API ERROR: ", err)
            callback(false, err)
        }
    }
}

export function APIDeleteOEMOrderMessage(messageId, callback) {
    return async dispatch => {
        try {
            const url = `/api/customer/oem/order/message/${messageId}`
            let rsp = await fetch(url, {
                method:'DELETE'
            })
            const isSuccess = HandleResponseError(rsp, dispatch)
            const json = await HandleResponseJson(rsp)
            const error = json.message
            callback(isSuccess, error)
        } catch (err) {
            console.error("API ERROR: ", err)
            callback(false, err)
        }
    }
}

/*
{
  "id": 0,
  "messageId": 0,
  "body": "string",
  "sender": {
    "id": 0,
    "name": "string",
    "company": "string"
  },
  "files,omitempty": [
    {
      "id": 0,
      "name": "string",
      "size": "string",
      "note": "string",
      "downloadLink": "string",
      "createAt": "string",
      "updatedAt": "string"
    }
  ],
  "createAt": "string",
  "updatedAt": "string"
}
*/
export function APIGetOEMOrderMessage(messageId, callback) {
    if (typeof messageId === 'undefined' || messageId === null || messageId.length === 0) {
        return async dispatch => {
            callback(false, undefined, "No Message ID")
        }
    }
    return async dispatch => {
        try {
            const url = `/api/customer/oem/order/message/${messageId}`
            let rsp = await fetch(url)
            const json = await HandleResponseJson(rsp)
            const data = json.data
            const error = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, data, error)
        } catch (err) {
            console.error("API ERROR: ", err)
            callback(false, undefined, err)
        }
    }
}

/*
Request Payload
{
  "messageId": 0,
  "body": "string"
}
Response
{
  "id": 0,
  "messageId": 0,
  "body": "string",
  "sender": {
    "id": 0,
    "name": "string",
    "company": "string"
  },
  "files,omitempty": [
    {
      "id": 0,
      "name": "string",
      "size": "string",
      "note": "string",
      "downloadLink": "string",
      "createAt": "string",
      "updatedAt": "string"
    }
  ],
  "createAt": "string",
  "updatedAt": "string"
}
*/
export function APIUpdateOEMOrderMessage(id, messageId, body, callback) {
    return async dispatch => {
        try {
            const url = `/api/customer/oem/order/message/${id}`
            let rsp = await fetch(url, {
                method:'PUT',
                body: JSON.stringify({
                  messageId,
                  body
                })
            })
            const json = await HandleResponseJson(rsp)
            const data = json.data
            const error = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, data, error)
        } catch (err) {
            console.error("API ERROR: ", err)
            callback(false, undefined, err)
        }
    }
}