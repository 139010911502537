import { APISignOut } from "./auth";

export const HandleResponseJson = async (rsp) => {
    const data = await rsp.text()
    return data ? JSON.parse(data) : {}
}

export const HandleResponseError = (rsp, dispatch) => {
    if (rsp.status === 401 || rsp.status === 403) {
        dispatch(APISignOut())
    }
    return rsp.status < 300
}