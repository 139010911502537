import { HandleResponseJson, HandleResponseError  } from './api'
import {
    getOEMOrders,
    getOEMOrderInfo
} from "../store/oemOrderSlice"

/*
[
  {
    "id": 0,
    "name": "string",
    "description": "string",
    "reference": "string",
    "status": "string",
    "items,omitempty": [
      {
        "id": 0,
        "name": "string",
        "note": "string",
        "quantity": 0,
        "price": "string",
        "files,omitempty": [
          {
            "id": 0,
            "name": "string",
            "size": "string",
            "note": "string",
            "downloadLink": "string",
            "createAt": "string",
            "updatedAt": "string"
          }
        ],
        "createAt": "string",
        "updatedAt": "string"
      }
    ],
    "contacts,omitempty": [
      {
        "id": 0,
        "name": "string",
        "title": "string",
        "duty": "string",
        "email": "string",
        "phone": "string",
        "fax": "string",
        "address": "string",
        "city": "string",
        "postal": "string",
        "state": "string",
        "country": "string",
        "createAt": "string",
        "updatedAt": "string"
      }
    ],
    "createdAt": "string",
    "updatedAt": "string"
  }
]
*/
export function APIGetOEMOrders(callback) {
    return async dispatch => {
        try {
            const url = '/api/customer/oem/order'
            let rsp = await fetch(url)
            const json = await HandleResponseJson(rsp)
            const data = json.data
            const errorMessage = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, data, errorMessage)
            return dispatch(getOEMOrders({
                data
            }))
        } catch (err) {
            console.error("API ERROR: ", err)
            callback(false, undefined, err)
            return dispatch(getOEMOrders({
                error: err
            }))
        }
    }
}

/*
{
  "id": 0,
  "name": "string",
  "description": "string",
  "reference": "string",
  "status": "string",
  "items,omitempty": [
    {
      "id": 0,
      "name": "string",
      "note": "string",
      "quantity": 0,
      "price": "string",
      "files,omitempty": [
        {
          "id": 0,
          "name": "string",
          "size": "string",
          "note": "string",
          "downloadLink": "string",
          "createAt": "string",
          "updatedAt": "string"
        }
      ],
      "createAt": "string",
      "updatedAt": "string"
    }
  ],
  "contacts,omitempty": [
    {
      "id": 0,
      "name": "string",
      "title": "string",
      "duty": "string",
      "email": "string",
      "phone": "string",
      "fax": "string",
      "address": "string",
      "city": "string",
      "postal": "string",
      "state": "string",
      "country": "string",
      "createAt": "string",
      "updatedAt": "string"
    }
  ],
  "createdAt": "string",
  "updatedAt": "string"
}
*/
export function APICreateOEMOrder(name, description, callback) {
    return async dispatch => {
        try {
            const url = '/api/customer/oem/order'
            let rsp = await fetch(url, {
                method:'POST',
                body: JSON.stringify({
                    name, // Required
                    description
                })
            })
            const json = await HandleResponseJson(rsp)
            const data = json.data
            const error = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, data, error)
        } catch(err) {
            console.error("API ERROR: ", err)
            callback(false, undefined, err)
        }
    }
}

export function APIDeleteOEMOrder(orderId, callback) {
    return async dispatch => {
        try {
            const url = `/api/customer/oem/order/${orderId}`
            let rsp = await fetch(url, {
                method:'DELETE'
            })
            const isSuccess = HandleResponseError(rsp, dispatch)
            const json = await HandleResponseJson(rsp)
            const error = json.message
            callback(isSuccess, error)
        } catch (err) {
            console.error("API ERROR: ", err)
            callback(false, err)
        }
    }
}

/*
{
  "id": 0,
  "name": "string",
  "description": "string",
  "reference": "string",
  "status": "string",
  "items,omitempty": [
    {
      "id": 0,
      "name": "string",
      "note": "string",
      "quantity": 0,
      "price": "string",
      "files,omitempty": [
        {
          "id": 0,
          "name": "string",
          "size": "string",
          "note": "string",
          "downloadLink": "string",
          "createAt": "string",
          "updatedAt": "string"
        }
      ],
      "createAt": "string",
      "updatedAt": "string"
    }
  ],
  "contacts,omitempty": [
    {
      "id": 0,
      "name": "string",
      "title": "string",
      "duty": "string",
      "email": "string",
      "phone": "string",
      "fax": "string",
      "address": "string",
      "city": "string",
      "postal": "string",
      "state": "string",
      "country": "string",
      "createAt": "string",
      "updatedAt": "string"
    }
  ],
  "createdAt": "string",
  "updatedAt": "string"
}
*/
export function APIGetOEMOrder(id, callback) {
    if (typeof id === 'undefined' || id === null || id.length === 0) {
        return async dispatch => {
            callback(false, undefined, "No Order ID")
        }
    }
    return async dispatch => {
        try {
            const url = `/api/customer/oem/order/${id}`
            let rsp = await fetch(url)
            const json = await HandleResponseJson(rsp)
            const data = json.data
            const error = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, data, error)
            if (isSuccess) {
                dispatch(getOEMOrderInfo({ data }))
            }
        } catch (err) {
            console.error("API ERROR: ", err)
            callback(false, undefined, err)
            return dispatch(getOEMOrderInfo({
                error: err
            }))
        }
    }
}

/*
{
  "id": 0,
  "name": "string",
  "description": "string",
  "reference": "string",
  "status": "string",
  "items,omitempty": [
    {
      "id": 0,
      "name": "string",
      "note": "string",
      "quantity": 0,
      "price": "string",
      "files,omitempty": [
        {
          "id": 0,
          "name": "string",
          "size": "string",
          "note": "string",
          "downloadLink": "string",
          "createAt": "string",
          "updatedAt": "string"
        }
      ],
      "createAt": "string",
      "updatedAt": "string"
    }
  ],
  "contacts,omitempty": [
    {
      "id": 0,
      "name": "string",
      "title": "string",
      "duty": "string",
      "email": "string",
      "phone": "string",
      "fax": "string",
      "address": "string",
      "city": "string",
      "postal": "string",
      "state": "string",
      "country": "string",
      "createAt": "string",
      "updatedAt": "string"
    }
  ],
  "createdAt": "string",
  "updatedAt": "string"
}
*/
export function APICloneOEMOrder(orderId, name, description, callback) {
    return async dispatch => {
        try {
            const url = `/api/customer/oem/order/${orderId}`
            let rsp = await fetch(url, {
                method:'POST',
                body: JSON.stringify({
                    name, // Required
                    description
                })
            })
            const json = await HandleResponseJson(rsp)
            const data = json.data
            const error = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, data, error)
        } catch(err) {
            console.error("API ERROR: ", err)
            callback(false, undefined, err)
        }
    }
}

/*
{
  "id": 0,
  "name": "string",
  "description": "string",
  "reference": "string",
  "status": "string",
  "items,omitempty": [
    {
      "id": 0,
      "name": "string",
      "note": "string",
      "quantity": 0,
      "price": "string",
      "files,omitempty": [
        {
          "id": 0,
          "name": "string",
          "size": "string",
          "note": "string",
          "downloadLink": "string",
          "createAt": "string",
          "updatedAt": "string"
        }
      ],
      "createAt": "string",
      "updatedAt": "string"
    }
  ],
  "contacts,omitempty": [
    {
      "id": 0,
      "name": "string",
      "title": "string",
      "duty": "string",
      "email": "string",
      "phone": "string",
      "fax": "string",
      "address": "string",
      "city": "string",
      "postal": "string",
      "state": "string",
      "country": "string",
      "createAt": "string",
      "updatedAt": "string"
    }
  ],
  "createdAt": "string",
  "updatedAt": "string"
}
*/
export function APIUpdateOEMOrder(orderId, name, description, callback) {
    return async dispatch => {
        try {
            const url = `/api/customer/oem/order/${orderId}`
            let rsp = await fetch(url, {
                method:'PUT',
                body: JSON.stringify({
                    name, // Required
                    description
                })
            })
            const json = await HandleResponseJson(rsp)
            const data = json.data
            const error = json.message
            const isSuccess = HandleResponseError(rsp, dispatch)
            callback(isSuccess, data, error)
            if (isSuccess) {
                dispatch(getOEMOrderInfo({ data }))
            }
        } catch (err) {
            console.error("API ERROR: ", err)
            callback(false, undefined, err)
        }
    }
}