import React, { Component } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { APISignUpWithCode } from '../../../apis/promotion'
import MCJ from '../../../common/def'
import bkImg from '../../../assert/promotion.jpg'
import './promotion.css'

class PromotionSignUp extends Component {
    constructor() {
        super()
        this.state = {
            email:"",
            fullName:"",
            phone:"",
            password:"",
            passwordRP:"",
            city:"",
            state:"",
            country:"US",
            address:"",
            postal:"",
            company:"",
            isCreatingAccount: false
        }
    }

    componentDidMount() {
        const {info} = this.props.promoState
        if (typeof info !== 'undefined' && info !== null) {
            this.setState({
                email:info.contactEmail,
                fullName:info.fullName,
                phone:info.phone,
                address:info.address,
                city:info.city,
                state:info.state,
                country:info.country,
                postal:info.postal,
                company:info.company,
                isCreatingAccount: false
            })
        }
    }

    onValueChange = e => {
        this.setState({
            [e.target.name]:e.target.value,
            [e.target.name + 'Error']:""
        })
    }

    validateName = () => {
        if(this.state.fullName === "") {
            this.setState({
              fullNameError: 'Enter your name',
            })
            return false
        }
        return true
    }

    validateEmail = () => {
        if(this.state.email === "") {
            this.setState({
              emailError: 'Enter your email',
            })
            return false
        }
        //var pattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        var pattern = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
    
        let bEmailOK = pattern.test(this.state.email)
        if (bEmailOK) {
          return true
        }
    
        this.setState({
            emailError: "Invalid email"
        })
        return false
    }

    validateEmpty = (name) => {
        const value = this.state[name]
        if(value === undefined || value === null || value.trim() === "") {
            this.setState({
                [name + 'Error']: `Enter your ${name}`
            })
            return false
        }
        return true
    }

    validatePhone = () => {
        if(this.state.phone === "") {
          this.setState({
            phoneError: 'Enter your phone number',
          })
          return false
        }
        return true
      }

      validatePassword = () => {
        if(this.state.password.length < 6) {
              this.setState({
              passwordError: 'Minimum 6 characters required',
            })
            return false
        }
        return true
      }
    
      validatePasswordRP = () => {
        if(this.state.password !== this.state.passwordRP) {
            this.setState({
              passwordRPError: 'Passwords do not match',
            })
            return false
        }
        return true
      }  

    renderItem = (inObj) => {
        return <div className='pmItem'>
            <div className='siTitle'>{inObj.title}</div>
            <div>
                <input className='pmInput' value={this.state[inObj.name]} name={inObj.name} placeholder={inObj.holder} type={inObj.type} onChange={this.onValueChange} />
            </div>
            <div>
                <span className='pmError'>{this.state[inObj.name + 'Error']}</span>
            </div>
        </div>
    }

    signUp = async () => {
        let isValidated = true
        isValidated = this.validateEmail() && isValidated
        isValidated = this.validateName() && isValidated
        isValidated = this.validatePhone() && isValidated
        isValidated = this.validatePassword() && isValidated
        isValidated = this.validatePasswordRP() && isValidated
        isValidated = this.validateEmpty('address') && isValidated
        isValidated = this.validateEmpty('city') && isValidated
        isValidated = this.validateEmpty('state') && isValidated
        isValidated = this.validateEmpty('country') && isValidated
        isValidated = this.validateEmpty('postal') && isValidated
        if (!isValidated) {
            return
        }
        const reqObj = {
            email:this.state.email,
            fullName:this.state.fullName,
            phone:this.state.phone,
            password:this.state.password,
            address:this.state.address,
            city:this.state.city,
            state:this.state.state,
            country:this.state.country,
            postal:this.state.postal,
            company:this.state.company,
            code:this.props.promoState.code
        }
        this.setState({ isCreatingAccount: true })
        await this.props.APISignUpWithCode(reqObj)
        this.setState({ isCreatingAccount: false })
    }
   

    render() {
        const { signUpStatus } = this.props.promoState
        if (signUpStatus.status === MCJ.STATUS_LOAD_OK) {
            return (
            <div className='pmGreet'>
                <img className='pmImg'  src={bkImg} alt=''></img>
                <br/>
                <br/>
                <h3>
                    Thank you! Your 6 free packs are being packaged and shipped now and should arrive shortly!
                </h3>
                <br/>
                <h3>
                While you wait for it to arrive, please take a look around and see if there’s anything else you might be interested in.
                </h3>
                <Link to={'/'}><Button variant='warning'>Go to visit</Button></Link>
            </div>)
        }

        let email = this.renderItem({
            name:'email',
            title:'E-mail address',
            holder:'Email',
            type:'text'
        })
        let name = this.renderItem({
            name:'fullName',
            title:'Name',
            holder:'FullName',
            type:'text'
        })

        let phone = this.renderItem({
            name:'phone',
            title:'Phone',
            holder:'Phone',
            type:'text'
        })

        let password = this.renderItem({
            name:'password',
            title:'Password',
            holder:'At least 6 characters',
            type:'password'
        })

        let passwordRP = this.renderItem({
            name:'passwordRP',
            title:'Re-enter password',
            holder:'',
            type:'password'
        })

        let address = this.renderItem({
            name:'address',
            title:'Address',
            holder:'Address',
            type:'text'
        })

        let city = this.renderItem({
            name:'city',
            title:'City',
            holder:'City',
            type:'text'
        })

        let state = this.renderItem({
            name:'state',
            title:'State',
            holder:'State',
            type:'text'
        })

        let country = this.renderItem({
            name:'country',
            title:'Country',
            holder:'Country',
            type:'text'
        })

        let postal = this.renderItem({
            name:'postal',
            title:'Postal',
            holder:'Postal',
            type:'text'
        })

        let company = this.renderItem({
            name:'company',
            title:'Company',
            holder:'Company',
            type:'text'
        })

        return (            
            <div className='pmSignUp'>
                <div className='pmTitle'>
                    Create account to get 6 free packs
                </div>
                {email}
                {name}
                {phone}
                {password}
                {passwordRP}
                {address}
                {postal}
                {city}
                {state}
                {country}
                {company}
                <div className='pmItem'>
                    <span style={{color: 'red', textTransform: 'capitalize'}}>{signUpStatus.errorMessage}</span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center'}}>
                    {this.state.isCreatingAccount === true && <Spinner className='spinner-border-sm' animation='border' style={{ alignSelf: 'center', color: 'white', marginRight: '8px', width: '20px', height: '20px' }} />}
                    <Button disabled={this.state.isCreatingAccount === true} variant='warning' onClick={this.signUp}>Create</Button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    promoState: state.promoState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        APISignUpWithCode
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionSignUp)