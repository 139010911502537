import { createSlice } from '@reduxjs/toolkit'

// Extract imperial/metric size if it's TCTL/HSS, otherwise return sku directly
function extractSortFactor(sku) {
  if (sku.startsWith("McJTCTL") || sku.startsWith("McJM2HSS")) {
    // Imperial
    const imperialFormat = /McJ.*-(\d+)-([\d/]+)"?/
    const imperialMatches = sku.match(imperialFormat)
    if (imperialMatches != null && imperialMatches.length === 3) {
      let result = 0.0
      result += parseFloat(imperialMatches[1])
      const fraction = imperialMatches[2]
      if (fraction.includes('/')) {
        const parts = fraction.split('/')
        const part1 = parseFloat(parts[0])
        const part2 = parseFloat(parts[1])
        if (part2 !== 0) {
          result += part1/part2
        }
      }
      return result
    } else if (sku.includes("mm")) {
      const regexp = /^McJ.*-([\d.]+)mm$/
      const matches = sku.match(regexp)
      return parseFloat(matches[1])
    }
  } else {
    return sku
  }
}

const prodSlice = createSlice({
  name: "prodSlice",
  initialState: {
      currCategoryId: undefined,
      categories: [],        // Categories from GET /customer/product/category
      productIdsByCategory: {}, // Products by Category from /customer/product?categoryId=categoryId
      allProductsById: {},          // All Products from GET /anonymous/product
      productDetailsById: {}        // Cached Product Details
  },
  reducers: {
    getCategories: (state, action) => {
      state.categories = action.payload.data
      if (state.categories.length > 0 && state.currCategoryId === undefined) {
        state.currCategoryId = state.categories[0].id
      }
    },
    getProductsByCategory: (state, action) => {
      const products = action.payload.data.sort((a, b) => {
        return (extractSortFactor(a.msku) > extractSortFactor(b.msku)) ? 1 : -1
      })
      if (typeof products !== 'undefined' && products !== null) {
        const categoryId = action.payload.categoryId
        state.productIdsByCategory = {
          ...state.productIdsByCategory,
          [categoryId]: products.map(p => p.id)
        }
      }
    },
    setCurrCategoryId: (state, action) => {
      state.currCategoryId = action.payload
    },
    getProducts: (state, action) => {
      const allProducts = action.payload.data.sort((a, b) => (a.msku > b.msku) ? 1 : -1)
      let allProductsById = {}
      allProducts.forEach(item => {
          allProductsById[item.id] = item
      });
      state.allProductsById = allProductsById
    },
    getProductDetail: (state, action) => {
      if (action.payload.ret) {
        let newProduct = action.payload.data  
        state.productDetailsById = {
          ...state.productDetailsById,
          [newProduct.id]: newProduct
        }
      }
    }
  }
})

export const {
  getCategories,
  getProductsByCategory,
  setCurrCategoryId,
  getProducts,
  getProductDetail
} = prodSlice.actions

export default prodSlice