import { bindActionCreators } from '@reduxjs/toolkit'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    APIGetOEMOrderMessages,
    // APIGetOEMOrderMessage,
    APICreateOEMOrderMessage,
    APIDeleteOEMOrderMessage,
    APIUpdateOEMOrderMessage,
} from '../../apis/oemOrderMessage'
import {
    // APIGetOEMOrderMessageFiles,
    // APIGetOEMOrderMessageFile,
    APICreateOEMOrderMessageFile,
    APIUpdateOEMOrderMessageFile,
    APIDeleteOEMOrderMessageFile
} from '../../apis/oemOrderMessageFile'
import './oemOrderMessages.css'
import moment from 'moment/moment'
import { Button, Modal, Spinner } from 'react-bootstrap'
import prettyBytes from 'pretty-bytes'
import { withRouter } from '../../common/withRouter'


class OEMOrderMessages extends Component {

    constructor() {
        super()
        this.state = {
            messages: [],
            // New Message
            newMessage: null,
            newMessageReplyMsg: null,
            newMessageError: null,
            // Edit Message
            isShowingEditModal: false,
            editMessageId: null,
            editMessage: null,
            editMessageReplyMsg: null,
            editMessageError: null,
            // Delete Message
            isShowingDeleteMessageModal: false,
            deleteMessage: null,
            deleteMessageError: null,
            // Upload File
            isShowingUploadFileModal: false,
            isUploadingFile: false,
            uploadFileMsgId: null,
            uploadFile: null,
            uploadFileNote: null,
            uploadFileError: null,
            // Edit File
            isShowingEditFileModal: false,
            editFile: null,
            editFileNote: null,
            editFileError: null
        }
    }

    componentDidMount() {
        this.loadMessages()
    }

    onValueChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    loadMessages() {
        const { orderId } = this.props
        this.props.APIGetOEMOrderMessages(
            orderId,
            (success, messages, error) => {
            this.setState({ messages: messages })
        })
    }

    editMessage() {
        this.props.APIUpdateOEMOrderMessage(
            this.state.editMessageId,
            this.state.editMessageReplyMsg?.id,
            this.state.editMessage,
            (success, message, error) => {
                this.setState({ editMessageError: error })
                if (success) {
                    this.setState({ isShowingEditModal: false })
                    this.loadMessages()
                }
        })
    }

    deleteMessage(messageId) {
        this.props.APIDeleteOEMOrderMessage(
            messageId,
            (success, error) => {
                this.setState({ deleteMessageError: error })
                if (success) {
                    this.setState({ isShowingDeleteMessageModal: false })
                    this.loadMessages()
                }
            })
    }

    createMessage() {
        const replyToMsgId = this.state.newMessageReplyMsg?.id
        this.props.APICreateOEMOrderMessage(
            this.props.orderId,
            replyToMsgId,
            this.state.newMessage,
             (success, error) => {
                if (success) {
                    this.setState({ newMessageReplyMsg: null, newMessage: null })
                    document.getElementById("newMessageTextArea").value = null
                    this.loadMessages()
                } else {
                    this.setState({ newMessageError: error })
                }
        })
    }

    formatMessageSenderString = (sender) => {
        let result = sender.name
        if (typeof sender.company !== 'undefined' && sender.company !== null && sender.company.length > 0) {
            result += ` (${sender.company})`
        }
        return result
    }

    formatMessageDateString = (dateString) => {
        if (typeof dateString === 'undefined' || dateString === null) {
            return ""
        }
        const mnt = moment.utc(dateString).local()
        return mnt.format('LLL')
    }

    findReplyMessage = (messageId) => {
        let replyMsg = null
        if (messageId !== null && messageId !== 0) {
            replyMsg = this.state.messages.filter(message => message.id === messageId)[0]
            if (replyMsg === undefined) {
                replyMsg = {
                    sender: {
                        name: "",
                        company: ""
                    },
                    body: "Message Not Found"
                }
            }
        }
        return replyMsg
    }

    renderMessageQuotedMessage(replyToMsg) {
        return <div className='OrderMessageReply'>
            <span>
                {`${this.formatMessageSenderString(replyToMsg.sender)}`}
            </span>
            <span style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                marginRight: '4px'
            }}>{replyToMsg.body}</span>
            <span>
                {`${this.formatMessageDateString(replyToMsg.updatedAt)}`}
            </span>
        </div>
    }

    renderMessageActions(message) {
        return <div style={{ display: 'flex' }}>
            <div style={{ flexGrow: 1 }} />
            <Button
                className='CommonButton Transparent shadow-none'
                style={{
                    fontSize: '11px',
                    textDecoration: 'underline',
                    marginRight: '4px'
                }}
                variant="warning"
                onClick={ () => {
                    this.setState({
                        deleteMessage: message,
                        deleteMessageError: "",
                        isShowingDeleteMessageModal: true
                    })
                } }>
                Delete
            </Button>
            <Button
                className='CommonButton Transparent shadow-none'
                style={{
                    fontSize: '11px',
                    textDecoration: 'underline',
                    marginRight: '4px'
                }}
                variant="warning"
                onClick={ () => {
                    const replyToMsg = this.findReplyMessage(message.messageId)
                    this.setState({
                        editMessageId: message.id,
                        editMessage: message.body,
                        editMessageReplyMsg: replyToMsg,
                        editMessageError: "",
                        isShowingEditModal: true
                    })
                } }>
                Edit
            </Button>
            <Button
                className='CommonButton Transparent shadow-none'
                style={{
                    fontSize: '11px',
                    textDecoration: 'underline'
                }}
                variant="warning"
                onClick={ () => {
                    this.setState({ newMessageReplyMsg: message })
                } }>
                Reply
            </Button>
        </div>
    }

    renderMessageFiles(message, showActions) {
        const files = message.files || []
        return (
            <div className='OrderMessageFiles'>
                <div className='OrderMessageFilesHeader'>
                    <span style={{ marginLeft: '4px' }}>
                        Attachments
                    </span>
                    <div style={{ flexGrow: 1 }} />
                    { showActions && (
                        <Button
                            className='CommonButton Transparent shadow-none'
                            style={{
                                fontSize: '11px',
                                textDecoration: 'underline',
                                marginRight: '4px',
                                color: 'white'
                            }}
                            onClick={ () => {
                                this.setState({
                                    isShowingUploadFileModal: true,
                                    uploadFileMsgId: message.id,
                                    uploadFile: null,
                                    uploadFileError: null
                                })
                            } }>
                            Upload
                        </Button>
                    )}
                </div>
                <div style={{ backgroundColor: 'lightgray' }}>
                    <div className='OrderMessageFilesList'>
                        { files.length === 0 && (
                            <span style={{ textAlign: 'center', color: 'gray' }}>
                                Empty
                            </span>
                        )}
                        { files.length > 0 && (
                            files.map( file => {
                                return (
                                    <div key={file.id} className='OrderMessageFilesListItem'>
                                        <div className='OrderMessageFilesListItemFile'>
                                            <a className='OrderMessageFilesListItemLink'
                                                href={file.downloadLink}>
                                                {file.name}
                                            </a>
                                            <div style={{ flexGrow: 1 }} />
                                            <span className='OrderMessageFilesListItemFileSize'>
                                                {prettyBytes(Number(file.size))}
                                            </span>
                                        </div>
                                        <span className='OrderMessageFilesListItemNote'>
                                            {file.note}
                                        </span>
                                        <div className='OrderMessageFilesListItemFooter'>
                                            <span>{this.formatMessageDateString(file.updatedAt)}</span>
                                            <div style={{ flexGrow: 1 }} />
                                            { showActions && (
                                                <Button
                                                    className='CommonButton Transparent shadow-none'
                                                    style={{
                                                        fontSize: '10px',
                                                        textDecoration: 'underline',
                                                        color: 'black'
                                                    }}
                                                    onClick={ () => {
                                                        this.setState({
                                                            editFile: file,
                                                            editFileError: null,
                                                            isShowingEditFileModal: true
                                                        })
                                                    } }>
                                                    Edit
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                )
                            })
                        )}
                    </div>
                </div>
            </div>
        )
    }

    renderMessage(message, showActions=true) {
        if (typeof message === 'undefined' || message === null) {
            return null
        }
        const header = `${this.formatMessageSenderString(message.sender)}`
        const footer = `${this.formatMessageDateString(message.updatedAt)}`
        const messageBody = message.body
        const replyToMsgId = message.messageId
        const replyToMsg = this.findReplyMessage(replyToMsgId)
        return (
            <div key={message.id} className='OrderMessage'>
                <div className='OrderMessageHeader'>
                    <span style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}>{header}</span>
                </div>
                <div className='OrderMessageBody'>
                    { replyToMsg && this.renderMessageQuotedMessage(replyToMsg) }
                    <span>{messageBody}</span>
                </div>
                <div className='OrderMessageFooter'>
                    <span style={{
                        flexGrow: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        marginRight: '4px',
                        color: 'gray'
                    }}>{footer}</span>
                    { showActions && this.renderMessageActions(message)}
                </div>
                { this.renderMessageFiles(message, showActions) }
            </div>
        )
    }

    renderMessageEditorQuotedMessage = (replyToMsg, showBriefReplyMsg, clearHandler) => {
        return <div style={{ display: 'flex', flexDirection: 'column'}}>
            <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', marginLeft: '12px', marginTop: '8px'}}>
                <span style={{ fontSize: '11px', flexGrow: 1 }}>Reply to:</span>
                <Button
                    className='CommonButton Transparent shadow-none'
                    style={{
                        fontSize: '11px',
                        textDecoration: 'underline',
                        marginRight: '8px'
                    }}
                    variant="warning"
                    onClick={ () => {
                        clearHandler()
                    } }>
                    Clear
                </Button>
            </div>
            <div className='OrderMessageNewMsgReply'>
                <span>
                    {`${this.formatMessageSenderString(replyToMsg.sender)}`}
                </span>
                <span style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: `${showBriefReplyMsg ? 'nowrap' : 'normal'}`,
                    marginRight: '4px'
                }}>{replyToMsg.body}</span>
                <span>
                    {`${this.formatMessageDateString(replyToMsg.updatedAt)}`}
                </span>
            </div>
        </div>
    }

    renderMessageEditor = (message, replyToMsg, showBriefReplyMsg, clearHandler, updateHandler) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                { replyToMsg && this.renderMessageEditorQuotedMessage(replyToMsg, showBriefReplyMsg, clearHandler) }
                <textarea
                    id="newMessageTextArea"
                    className='OrderMessageNewMsgContentTextarea'
                    defaultValue={message || ""}
                    name='newMessage'
                    onChange={(e) => {
                        updateHandler(e.target.value)
                    }}>
                </textarea>
            </div>
        )
    }

    renderNewMessage = () => {
        const replyToMsg = this.state.newMessageReplyMsg
        return (
            <div className='OrderMessageNewMsg'>
                <div className='OrderMessageHeader'>
                    <span>New Message</span>
                    <div style={{ flexGrow: 1 }}></div>
                    <span></span>
                </div>
                {
                    this.renderMessageEditor(
                        this.state.newMessage?.body,
                        replyToMsg,
                        true,
                        () => { this.setState({ newMessageReplyMsg: null }) },
                        (updatedMessage) => { this.setState({ 'newMessage': updatedMessage, 'newMessageError': "" }) }
                    )
                }
                <span
                    className='CommonErrorMessage'
                    style={{marginLeft: '8px', marginRight: '8px'}}>
                    {this.state.newMessageError}
                </span>
                <Button
                    className='CommonButton shadow-none'
                    disabled={!this.state.newMessage}
                    style={{
                        marginBottom: '8px',
                        marginRight: '8px',
                        height: '24px',
                        fontSize: '12px',
                        alignSelf: 'flex-end',
                        flexShrink: 1
                    }}
                    variant="warning"
                    onClick={ () => {
                        this.createMessage()
                    } }>
                    {replyToMsg ? "REPLY": "SEND"}
                </Button>
            </div>
        )
    }

    renderEditMessageModal = () => {
        return (
            <Modal size='md' show={ this.state.isShowingEditModal } onHide={ () => { this.setState({ isShowingEditModal: false }) } }>
                <div className='CommonDialogHeader'>
                    <h3 className='CommonDialogTitle'>Edit Message</h3>
                </div>
                <hr style={{ margin: '0px' }}/>
                <Modal.Body>
                {
                    this.renderMessageEditor(
                        this.state.editMessage,
                        this.state.editMessageReplyMsg,
                        false,
                        () => {
                            this.setState({ editMessageReplyMsg: null })
                        },
                        (updatedMessage) => {
                            this.setState({
                                'editMessage': updatedMessage,
                                'editMessageError': ""
                            })
                        }
                    )
                }
                <span className='CommonErrorMessage'>{this.state.editMessageError}</span>
                </Modal.Body>
                <Modal.Footer className='CommonDialogFooter'>
                    <Button
                        className='CommonButton shadow-none'
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px',
                        }}
                        variant="secondary"
                        onClick={ () => { this.setState({ isShowingEditModal: false }) } }>
                        CANCEL
                    </Button>
                    <div style={{ flexGrow: 1 }}/>
                    <Button
                        className='CommonButton shadow-none'
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px'
                        }}
                        variant="warning"
                        disabled={ this.state.isUpdatingCategory}
                        onClick={ async () => {
                            this.editMessage()
                        }
                        }>
                        SAVE
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderDeleteMessageModal = () => {
        const deleteMessage = this.state.deleteMessage
        const deleteMessageId = deleteMessage?.id
        return (
            <Modal size='md' show={ this.state.isShowingDeleteMessageModal } onHide={ () => { this.setState({ isShowingDeleteMessageModal: false }) } }>
                <div className='CommonDialogHeader'>
                    <h3 className='CommonDialogTitle'>Delete Message?</h3>
                </div>
                <hr style={{ margin: '0px' }}/>
                <Modal.Body>
                {this.renderMessage(deleteMessage, false)}
                <span
                    className='CommonErrorMessage'>
                    {this.state.deleteMessageError}
                </span>
                </Modal.Body>
                <Modal.Footer className='CommonDialogFooter'>
                    <Button
                        className='CommonButton shadow-none'
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px',
                        }}
                        variant="secondary"
                        onClick={ () => { this.setState({ isShowingDeleteMessageModal: false }) } }>
                        CANCEL
                    </Button>
                    <div style={{ flexGrow: 1 }} />
                    <Button
                        className='CommonButton shadow-none'
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px'
                        }}
                        variant="danger"
                        disabled={ this.state.isUpdatingCategory}
                        onClick={ async () => {
                            this.deleteMessage(deleteMessageId)
                        }
                        }>
                        DELETE
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderUploadFileModal = () => {
        return (
            <Modal size='md' show={ this.state.isShowingUploadFileModal } onHide={ () => { this.setState({ isShowingUploadFileModal: false }) } }>
                <div className='CommonDialogHeader'>
                    <h3 className='CommonDialogTitle'>
                        Upload Attachment
                    </h3>
                </div>
                <hr style={{ margin: '0px' }}/>
                <Modal.Body>
                <form>
                    <div className="mb-3">
                        <label className="col-form-label">Pick a file</label>
                        <input
                            type="file"
                            className="form-control form-control-sm"
                            id="file"
                            onChange={ e => {
                                this.setState({ uploadFile: e.target.files[0] })
                            }} />   
                    </div>
                    <div className="mb-3">
                        <label className="col-form-label">Note</label>
                        <textarea
                            className="form-control form-control-sm"
                            onChange={ e => {
                                this.setState({ uploadFileNote: e.target.value })
                            }} />
                    </div>
                </form>
                <span className='CommonErrorMessage'>{this.state.uploadFileError}</span>
                </Modal.Body>
                <Modal.Footer className='CommonDialogFooter'>
                    <Button
                        className='CommonButton shadow-none'
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px',
                        }}
                        variant="secondary"
                        disabled={ this.state.isUploadingFile }
                        onClick={ () => { this.setState({ isShowingUploadFileModal: false }) } }>
                        CANCEL
                    </Button>
                    <div style={{ flexGrow: 1 }} />
                    {this.state.isUploadingFile === true && <Spinner animation='border' style={{ width: '24px', height: '24px', marginRight: '8px'}} />}
                    <Button
                        className='CommonButton shadow-none'
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px'
                        }}
                        variant="warning"
                        disabled={ this.state.isUploadingFile || this.state.uploadFile === null }
                        onClick={ async () => {
                            await this.setState({ isUploadingFile: true })
                            this.props.APICreateOEMOrderMessageFile(
                                this.state.uploadFileMsgId,
                                this.state.uploadFile,
                                this.state.uploadFileNote,
                                (success, error) => {
                                    if (success) {
                                        this.loadMessages()
                                        this.setState({
                                            isUploadingFile: false,
                                            isShowingUploadFileModal: false
                                        })
                                    } else {
                                        this.setState({
                                            isUploadingFile: false,
                                            uploadFileError: error
                                        })
                                    }
                                }
                            )
                        }}>
                        UPLOAD
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderEditFileModal = () => {
        return (
            <Modal size='md' show={ this.state.isShowingEditFileModal } onHide={ () => { this.setState({ isShowingEditFileModal: false }) } }>
                <div className='CommonDialogHeader'>
                    <h3 className='CommonDialogTitle'>
                        Edit Attachment
                    </h3>
                </div>
                <hr style={{ margin: '0px' }}/>
                <Modal.Body>
                <form>
                    <div className="mb-3" style={{ display: 'flex', flexDirection: 'column' }}>
                        <label className="col-form-label">File</label>
                        <label className="col-form-label form-control-sm">
                            {this.state.editFile?.name}
                        </label>
                    </div>
                    <div className="mb-3">
                        <label className="col-form-label">Note</label>
                        <textarea
                            className="form-control form-control-sm"
                            defaultValue={ this.state.editFile?.note }
                            onChange={ e => {
                                this.setState({ editFileNote: e.target.value })
                            }} />
                    </div>
                </form>
                <span className='CommonErrorMessage'>{this.state.editFileError}</span>
                </Modal.Body>
                <Modal.Footer className='CommonDialogFooter'>
                    <Button
                        className='CommonButton shadow-none'
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px',
                        }}
                        variant="secondary"
                        onClick={ () => { this.setState({ isShowingEditFileModal: false }) } }>
                        CANCEL
                    </Button>
                    <div style={{ flexGrow: 1 }} />
                    <Button
                        className='CommonButton shadow-none'
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px'
                        }}
                        variant="danger"
                        onClick={ () => {
                            this.props.APIDeleteOEMOrderMessageFile(
                                this.state.editFile.id,
                                (success, error) => {
                                    if (success) {
                                        this.setState({ isShowingEditFileModal: false })
                                        this.loadMessages()
                                    } else {
                                        this.setState({ editFileError: error })
                                    }
                                })
                        } }>
                        DELETE
                    </Button>
                    <Button
                        className='CommonButton shadow-none'
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px'
                        }}
                        variant="warning"
                        onClick={ async () => {
                            this.props.APIUpdateOEMOrderMessageFile(
                                this.state.editFile.id,
                                this.state.editFileNote,
                                (success, file, error) => {
                                    if (success) {
                                        this.loadMessages()
                                        this.setState({ isShowingEditFileModal: false })
                                    } else {
                                        this.setState({ editFileError: error })
                                    }
                                }
                            )
                        }}>
                        UPDATE
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        return (
            <div className='OrderMessagesContainer'>
                <div className='OrderMessageTitle'>
                    <span style={{ color: 'white' }}>Messages ({(this.state.messages ?? []).length})</span>
                </div>
                {(this.state.messages ?? []).map( (message) => {
                    return this.renderMessage(message)
                })}
                {this.renderNewMessage()}
                {this.renderEditMessageModal()}
                {this.renderDeleteMessageModal()}
                {this.renderUploadFileModal()}
                {this.renderEditFileModal()}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    authState: state.authState,
    ordersState: state.ordersState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        APIGetOEMOrderMessages,
        // APIGetOEMOrderMessage,
        APICreateOEMOrderMessage,
        APIDeleteOEMOrderMessage,
        APIUpdateOEMOrderMessage,
        // APIGetOEMOrderMessageFiles,
        // APIGetOEMOrderMessageFile,
        APICreateOEMOrderMessageFile,
        APIUpdateOEMOrderMessageFile,
        APIDeleteOEMOrderMessageFile
    }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OEMOrderMessages))
