import { BrowserRouter as Router, Routes, Route, Navigate, useParams, Outlet } from "react-router-dom"
import './App.css';
import React from 'react'
import SignUp from './components/auth/signup';
import SignIn from './components/auth/signin';
// import Shop from './components/shop/shop';
import Products from './components/shop/products/products';
import User from './components/shop/user/user';
import Cart from './components/shop/cart/cart';
import UserAccount from './components/shop/user/account';
import UserOrders from './components/shop/user/orders';
import UserAddresses from './components/shop/user/addresses';
import EditAddressWrap from './components/shop/user/editAddressWrap';
import ChangePsw from './components/shop/user/changepsw';
import Promotion from './components/shop/promotion/promotion';
import PromotionBrush from './components/shop/promotionBrush/promotionBrush';
import ProductWrap from './components/shop/product/productWrap';
import OrderWrap from './components/shop/order/orderWrap';
import Warranty from './components/shop/about/warranty';
import Privacy from './components/shop/about/privacy';
import ForgotPassword from './components/auth/forgot';
import ResetPassword from './components/auth/reset';
import ConfirmEmail from './components/auth/confirm';
import Home from './components/home/home';
import Landing from './components/landing/landing';
import Dealer from './components/dealer/dealer';
import OEM from './components/oem/oem';

import SmoothScroll from "smooth-scroll";
import OemOrderDetail from "./components/oem/oemOrderDetail";
import Store from "./components/store/store";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 100,
  speedAsDuration: true,
  easing: 'easeInQuad'
});

const CheckRegion = () => {
  const { region } = useParams();
  if (region !== 'au' && region !== 'nz') {
    return <Navigate to="/" replace />;
  }
  return <Outlet />;
}

const AllRoutes = (
  <>
    <Route index element={<Landing />} />
    <Route path="signin" element={<SignIn/>} />
    <Route path="signup" element={<SignUp/>} />
    <Route path='forgotpassword' element={<ForgotPassword />} />
    <Route path='free' element={<Promotion />} />
    <Route path='brush' element={<PromotionBrush />} />
    <Route path='account/resetPassword/:token' element={<ResetPassword />} />
    <Route path='account/confirmation/:token' element={<ConfirmEmail />} />
    <Route path="user" element={<User />} >
      <Route path="account" element={<UserAccount />} />
      <Route path="order" element={<UserOrders />} />
      <Route path="addresses" element={<UserAddresses />} />
      <Route path="addresses/edit" element={<EditAddressWrap />} />
      <Route path='changepsw' element={<ChangePsw />} />
    </Route>
    <Route path="products" element={<Products />} />
    <Route path='product/:id' element={<ProductWrap />} />
    <Route path="cart" element={<Cart />} />
    <Route path="cart/:ref" element={<Cart />} />
    <Route path='order/:id' element={<OrderWrap />} />
    <Route path='warranty' element={<Warranty />} />
    <Route path='privacy' element={<Privacy />} />
    <Route path="dealer" element={<Dealer />} />
    <Route path="oem" >
      <Route path="" element={<OEM />} />
      <Route path=":id" element={<OemOrderDetail />} />
    </Route>
    <Route path="store" element={<Store/>} />
  </>
);

const App = () => {
  return (
    <Router className='App'>
      <Routes>
        <Route path="/" element={<Home />}>
          { AllRoutes }
        </Route>
        <Route element={<CheckRegion />}>
          <Route path="/:region/*" element={<Home />}>
            { AllRoutes }
          </Route>
        </Route>
      </Routes>
    </Router>
  )
}

export default App;
