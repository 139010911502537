import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { AddressDropDown } from '../../../common/addressDropDown'

const CartEditAddressModal = ({
    showDialog,
    address,
    onHide,
    onSave
}) => {
    if (address === undefined || address === null) {
        address = {}
    }
    const [editAddress, setEditAddress] = useState(address)
    const countries = useSelector(state => { return state.userState.system?.countries || [] })
    const isNewAddress = typeof editAddress.id === 'undefined' || editAddress.id === null
    const currCountry = editAddress.country ?? 'US'
    const states = (countries.find(c => c.abbrev.toLowerCase() === currCountry.toLowerCase()) || { states: [] }).states
    
    const [isUpdatingAddress, setIsUpdatingAddress] = useState(false)
    const createOrUpdateAddressStatus = useSelector(state => { return state.userState.createOrUpdateAddressStatus })

    useEffect(() => {
        if (showDialog) {
            setEditAddress({
                ...address
            })
        }
    }, [showDialog, address])

    const onValueChange = (e) => {
        setEditAddress({
            ...editAddress,
            [e.target.name]: e.target.value
        })
    }

    return (
        <Modal size='md' show={ showDialog } onHide={ onHide }>
            <div className='CommonDialogHeader'>
                <h3 className='CommonDialogTitle'>
                    { isNewAddress ? 'Create' : 'Edit' } Address
                </h3>
            </div>
            <hr style={{ margin: '0px' }}/>
            <Modal.Body>
            <form>
                <div className="mb-1">
                    <label className="col-form-label">Name</label>
                    <input type="text" className="form-control" id="address-name" name='name' defaultValue={editAddress.name} onChange={onValueChange} />
                </div>
                <div className="mb-1">
                    <label className="col-form-label">Phone</label>
                    <input type="text" className="form-control" id="address-phone" name='phone' defaultValue={editAddress.phone} onChange={onValueChange} />
                </div>
                <div className="mb-1">
                    <label className="col-form-label">Email</label>
                    <input type="text" className="form-control" id="address-email" name='email' defaultValue={editAddress.email} onChange={onValueChange} />
                </div>
                <div className="mb-1">
                    <label className="col-form-label">Address</label>
                    <input type="text" className="form-control" id="address-address" name='address' defaultValue={editAddress.address} onChange={onValueChange} />
                </div>
                <div className="mb-1">
                    <label className="col-form-label">City</label>
                    <input type="text" className="form-control" id="address-city" name='city' defaultValue={editAddress.city} onChange={onValueChange} />
                </div>
                <div className="mb-1">
                    <label className="col-form-label">Postal</label>
                    <input type="text" className="form-control" id="address-postal" name='postal' defaultValue={editAddress.postal} onChange={onValueChange} />
                </div>
                <div className="mb-1">
                    <label className="col-form-label">State</label>
                    <div className='dropdownList'>
                        <AddressDropDown choiceKey='state' choiceValue={editAddress.state} choices={states} onClick={onValueChange} />
                    </div>
                </div>
                <div className="mb-1">
                    <label className="col-form-label">Country</label>
                    <div className='dropdownList'>
                        <AddressDropDown choiceKey='country' choiceValue={currCountry} choices={countries} onClick={onValueChange} />
                    </div>
                </div>
            </form>
            <span
                className='CommonErrorMessage'>
                {createOrUpdateAddressStatus?.errorMessage}
            </span>
            </Modal.Body>
            <Modal.Footer className='CommonDialogFooter'>
                <Button
                    className='CommonButton shadow-none'
                    style={{
                        marginLeft: '8px',
                        marginRight: '8px',
                        height: '32px',
                        fontSize: '14px',
                    }}
                    variant="secondary"
                    onClick={ onHide }>
                    CANCEL
                </Button>
                <div style={{ flexGrow: 1 }}/>
                <Button
                    className='CommonButton shadow-none'
                    style={{
                        marginLeft: '8px',
                        marginRight: '8px',
                        height: '32px',
                        fontSize: '14px'
                    }}
                    variant="warning"
                    disabled={ isUpdatingAddress}
                    onClick={ async () => {
                        setIsUpdatingAddress(true)
                        await onSave(editAddress)
                        setIsUpdatingAddress(false)
                    }}>
                    SAVE
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CartEditAddressModal