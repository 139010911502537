import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './categories.css'

export const Categories = (props) => {
    const navigate = useNavigate();
    const categories = useSelector(state => state.prodState.categories);

    const getCategoryImage = (categoryName) => {
        const lowerCaseName = categoryName.toLowerCase();
        if (lowerCaseName.includes('cleaning')) {
            return '/images/store/store_cleaning.jpg';
        } else if (lowerCaseName.includes('reloading')) {
            return '/images/store/store_reloading.jpg';
        } else {
            return '/images/store/store_saw.jpg';
        }
    };

    return (
        <main id="catalogue" className="text-center">
            <div className="container d-flex justify-content-between mt-5">
                <div className="container overflow-visible">
                    <div className="row">
                        {
                            categories.map((item, i) => (
                                <div
                                    key={`${i}.${item.name}`}
                                    className="col-6 col-lg-4 col-xl-3"
                                    style={{ marginTop: '24px', cursor: 'pointer' }}
                                    onClick={()=> {
                                        navigate(`/products?category=${item.id}`);
                                }}>
                                    <div className="card prod-card h-100 shadow">
                                    <div className="card-body position-relative p-0">
                                            <img src={getCategoryImage(item.name)} className="card-img-top img-fluid" alt={item.name} />
                                            <div className='category-overlay'>
                                                <span className="h6 h3-md h3-lg">{item.name}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </main>
    )
}

