export const Catalogue = (props) => {
        const list = [
        {
            "img": "/images/catalogue/catalogue_1.jpg",
            "title": 'Hole Cutters',
            "url": "https://mcjtools-prod-assets.s3.amazonaws.com/catalogues/McJTools-hole-cutter-22-23-op.pdf"
        },
        {
            "img": "/images/catalogue/catalogue_2.jpg",
            "title": 'Reloading',
            "url": "https://mcjtools-prod-assets.s3.amazonaws.com/catalogues/McJTools-reloading-22-23-op-high.pdf"
        },
        {
            "img": "/images/catalogue/catalogue_3.jpg",
            "title": 'Cleaning',
            "url": "https://mcjtools-prod-assets.s3.amazonaws.com/catalogues/McJ+Tools-cleaning-acc-24-25.pdf"
        }
    ]
    return (
        <main id="catalogue" className="text-center">
            <div className="container">
                <div className='section-title'>
                    <h2>Catalogues</h2>
                </div>
            </div>
            <div className="container d-flex justify-content-between mt-5">
                <div className="container overflow-visible">
                    <div className="row">
                        {
                            list.map((item, i) => (
                                <div key={`${i}.${item.name}`} className="col-lg-4 col-md-6 col-sm-6" style={{ marginTop: '24px'}}>
                                    <div className="card prod-card h-100 shadow">
                                        <div className="card-body">
                                            <a href={item.url} target="_blank" rel="noreferrer" style={{ 'textDecoration': 'none'}}>
                                                <img src={item.img} className="card-img-top img-fluid" alt={item.name} />
                                                <h3 className="card-title mt-3">{item.title}</h3>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </main>
    )
}
