import { bindActionCreators } from '@reduxjs/toolkit'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Dropdown, Spinner, Modal } from 'react-bootstrap'
import {
    APIUpdateOEMOrder,
    APIDeleteOEMOrder
} from '../../apis/oemOrder'
import './oemOrderCard.css'
import { OrderStatusFGColor } from './oemOrder'
import { withRouter } from '../../common/withRouter'

class OEMOrderCardEdit extends Component {

    constructor() {
        super()
        this.state = {
            isShowingEditOrder: false,
            editOrder: undefined,
            isEditingOrder: false,
            editOrderError: "",
            isShowingDeleteOrder: false
        }
    }

    componentDidMount() {
    }

    renderOrderStatusButton = () => {
        const orderStatus = this.state.editOrderStatus
        const possibleValues = [
            "CREATED",
            "PAID",
            "FULFILLED",
            "PARTIAL"
        ]
        return (
            <div>
                <Dropdown>
                    <Dropdown.Toggle variant="secondary" style={{
                        width: '100%',
                        height: '32px',
                        fontSize: '14px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        cursor: 'pointer'
                    }}>
                        {orderStatus ?? "UNKNOWN"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu align='end'>
                        {
                            possibleValues.map(status => {
                                return (
                                    <Dropdown.Item
                                        key={status}
                                        name={`${status}`}
                                        value={status}
                                        onClick={(e) => {
                                            this.setState({ editOrderStatus: status })
                                        }}>
                                        {`${status}`}
                                    </Dropdown.Item>
                                )
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        )
    }

    // Edit Order
    onValueChange = e => {
        this.setState(preState => {
            return {
                ...preState,
                editOrder: {
                    ...preState.editOrder,
                    [e.target.name]:e.target.value
                }
            }
        })
    }

    editOrderAction = () => {
        this.setState({
            isEditingOrder: true
        })
        this.props.APIUpdateOEMOrder(
            this.state.editOrder.id,
            this.state.editOrder.name,
            this.state.editOrder.description,
            (success, data, error) => {
                this.setState({
                    isEditingOrder: false,
                    isShowingEditOrder: !success,
                    editOrderError: error
                })
            })
    }

    deleteOrderAction = () => {
        this.setState({
            isEditingOrder: true
        })
        this.props.APIDeleteOEMOrder(
            this.state.editOrder.id,
            (success, error) => {
                this.setState({
                    isEditingOrder: false,
                    isShowingEditOrder: !success,
                    isShowingDeleteOrder: !success,
                    editOrderError: error
                })
                if (success) {
                    this.props.router.navigate('/oem')
                }
            })
    }

    renderDeleteOrderConfirmationModal = () => {
        return (
            <Modal className='bg-dark bg-opacity-50' size='sm' show={ this.state.isShowingDeleteOrder } onHide={ () => { this.setState({ isShowingDeleteOrder: false }) } }>
                <div className='CommonDialogHeader'>
                    <h3 className='CommonDialogTitle'>Delete Order #{this.state.editOrder?.id}?</h3>
                </div>
                <hr style={{ margin: '0px' }}/>
                <Modal.Footer className='CommonDialogFooter'>
                    <Button
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px',
                            paddingTop: '0px',
                            paddingBottom: '0px',
                            paddingLeft: '8px',
                            paddingRight: '8px'
                        }}
                        className='shadow-none'
                        variant="secondary"
                        onClick={ () => { this.setState({ isShowingDeleteOrder: false }) } }>
                        CANCEL
                    </Button>
                    <div style={{ flexGrow: 1 }}/>
                    {this.state.isEditingOrder === true && <Spinner animation='border' style={{ alignSelf: 'center', marginRight: '16px'}} />}
                    <Button
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px',
                            paddingTop: '0px',
                            paddingBottom: '0px',
                            paddingLeft: '8px',
                            paddingRight: '8px'
                        }}
                        className='shadow-none'
                        variant="danger"
                        disabled={ this.state.isEditingOrder}
                        onClick={ () => {
                            this.deleteOrderAction()
                        }}>
                        DELETE
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderEditOrderStatusModal = () => {
        return (
            <Modal size='md' show={ this.state.isShowingEditOrder } onHide={ () => { this.setState({ isShowingEditOrder: false }) } }>
                <div className='CommonDialogHeader'>
                    <h3 className='CommonDialogTitle'>Edit Order #{this.state.editOrder?.id}</h3>
                </div>
                <hr style={{ margin: '0px' }}/>
                <Modal.Body>
                <form>
                    <label className="col-form-label">Name:</label>
                    <input type="text" className="form-control" id="name" name='name' defaultValue={this.state.editOrder?.name} onChange={this.onValueChange} />
                    <label className="col-form-label">Description:</label>
                    <textarea
                        className="form-control"
                        id="description"
                        name='description'
                        defaultValue={this.state.editOrder?.description}
                        onChange={ this.onValueChange } />
                </form>
                <span className='CommonErrorMessage'>{this.state.editOrderError}</span>
                </Modal.Body>
                <Modal.Footer className='CommonDialogFooter'>
                    <Button
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px',
                            paddingTop: '0px',
                            paddingBottom: '0px',
                            paddingLeft: '8px',
                            paddingRight: '8px'
                        }}
                        className='shadow-none'
                        variant="secondary"
                        onClick={ () => { this.setState({ isShowingEditOrder: false }) } }>
                        CLOSE
                    </Button>
                    <div style={{ flexGrow: 1 }}/>
                    {this.state.isEditingOrder === true && <Spinner animation='border' style={{ alignSelf: 'center', marginRight: '16px'}} />}
                    <Button
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px',
                            paddingTop: '0px',
                            paddingBottom: '0px',
                            paddingLeft: '8px',
                            paddingRight: '8px'
                        }}
                        className='shadow-none'
                        variant="danger"
                        disabled={ this.state.isEditingOrder}
                        onClick={ () => {
                            this.setState({ isShowingDeleteOrder: true })
                        }}>
                        DELETE
                    </Button>
                    <div style={{ flexGrow: 1 }}/>
                    {this.state.isEditingOrder === true && <Spinner animation='border' style={{ alignSelf: 'center', marginRight: '16px'}} />}
                    <Button
                        style={{
                            marginLeft: '8px',
                            marginRight: '8px',
                            height: '32px',
                            fontSize: '14px',
                            paddingTop: '0px',
                            paddingBottom: '0px',
                            paddingLeft: '8px',
                            paddingRight: '8px'
                        }}
                        className='shadow-none'
                        variant="warning"
                        disabled={ this.state.isEditingOrder}
                        onClick={ () => {
                            this.editOrderAction()
                        }}>
                        SAVE
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        const order = this.props.order
        return (
            <>
                <Button
                    variant='warning'
                    className='CommonButton shadow-none'
                    style={{
                        backgroundColor: '#FFFFFF30',
                        borderColor: '#00000000',
                        color: OrderStatusFGColor(order.status),
                        marginTop: '-2px',
                        height: '20px',
                        fontSize: '12px'
                    }}
                    onClick={(e) => {
                        this.setState({
                            isShowingEditOrder: true,
                            editOrder: Object.assign({}, order),
                            editOrderError: ""
                        })
                    }}>
                    EDIT
                </Button>
                {this.renderDeleteOrderConfirmationModal()}
                {this.renderEditOrderStatusModal()}
            </>
        )
    }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        APIUpdateOEMOrder,
        APIDeleteOEMOrder
    }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OEMOrderCardEdit))