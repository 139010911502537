import React, { useState, useEffect } from 'react';

const RotatingImages = ({ images }) => {
  const [currIndex, setCurrIndex] = useState(0);

  useEffect(() => {
    if (currIndex < images.length) {
      const image = images[currIndex];
      const interval = setInterval(() => {
        setCurrIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, image.duration);
  
      return () => clearInterval(interval);
    }
  }, [currIndex, images]);

  if (images.length === 0) {
    return null
  }

  // const isMobile = window.innerWidth <= 768;
  const image = images[currIndex];
  const urlPrefix = 'https://mcjtools-prod-assets.s3.amazonaws.com/images'
  const imageUrl = urlPrefix + image.image

  return (
    <div style={{ width: 'auto', height: 'auto' }}>
      <a 
        href={image.url || 'store' }
        target="_blank" 
        rel="noopener noreferrer"
      >
        <img 
          src={imageUrl} 
          alt={image.title} 
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </a>
    </div>
  );
};

export const Promotions = (props) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const url = 'https://mcjtools-prod-assets.s3.amazonaws.com/images/banner/banner.json'
        const response = await fetch(url);
        if (!response.ok) {
          return
        }
        const json = await response.json();
        setImages(json);
      } catch (error) {
        console.error('Error fetching promotions:', error);
        // Optionally set some default data or show an error message
      }
    };

    fetchBanners();
  }, []);
  
  return (
    <section id='promotions' className='text-center'>
      <div className=''>
        {/* <div className='section-title'>
          <h2>Promotions</h2>
        </div> */}
        <div className='row align-items-center justify-content-center gap-4'>
            <div className='col-12'>
              <RotatingImages images={images} />
            </div>
        </div>
      </div>
    </section>
  )
}