import MCJ from "../common/def"
import {
    addToCart,
    getCart,
    getOrder,
    payOrder
} from "../store/cartSlice";
import { APIGetUserInfo } from "./user"
import { HandleResponseError, HandleResponseJson } from './api'

export function APIAddToCart(product, quantity) {
    quantity = parseInt(quantity)
    return async dispatch => {
        try {
            dispatch(addToCart({
                shoppingStatus:MCJ.SHOPPING_ADDING,
                newItem:product,
                quantity
            }))
            let reqUrl = `/api/customer/cart/${product.id}?quantity=${quantity}`
            let rsp = await fetch(reqUrl, {
                method:'put'
            })

            if(rsp.status === 200) {
                dispatch(addToCart({
                    shoppingStatus:MCJ.SHOPPING_ADDED,
                    newItem:product,
                    quantity
                }))
            } else {
                dispatch(addToCart({
                    shoppingStatus:MCJ.SHOPPING_ADD_FAILED,
                    newItem:product,
                    quantity
                }))
            }
            dispatch(APIGetCart())
            dispatch(APIGetUserInfo())
        } catch(err) {
            dispatch(addToCart({
                shoppingStatus:MCJ.SHOPPING_ADD_FAILED,
                newItem:product,
                quantity
            }))
        }
    }
}

export function APIGetCart() {
    return async dispatch => {
        try {
            let rsp = await fetch('/api/customer/cart')
            HandleResponseError(rsp, dispatch)
            let data = (await rsp.json()).data ?? []
            if (rsp.status === 200) {
                dispatch(getCart(data))
            } 

        } catch (err) {
            console.log('catch err when get cart', err)
        }
    }
}

export function ConfirmCartStatus() {
    return async dispatch => {
        dispatch(addToCart({
            shoppingStatus:MCJ.SHOPPING_INIT
        }))
    }
}

export function APIRemoveFromCart(productId) {
    return async dispatch => {
        try {
            await fetch(`/api/customer/cart/${productId}`, {
                method:'delete'
            })
            dispatch(APIGetCart())
            dispatch(APIGetUserInfo())
        } catch (err) {
            console.log('catch err when remove from action', err)
        }
    }
}

export function APICreateOrder(orderInfo, callback) {
    return async dispatch => {
        try {
            let rsp = await fetch('/api/customer/order', {
                method:'post',
                body:JSON.stringify(orderInfo)
            })
            const isSuccess = HandleResponseError(rsp, dispatch)
            const json = await HandleResponseJson(rsp)
            const error = json.message
            if (isSuccess) {
                dispatch(PayOrderAction(MCJ.PAYMENT_INIT))
            }
            if (error) {
                console.log("create order error, message:", error)
            }
            callback(isSuccess, json, error)
        } catch( err ) {
            console.error("API ERROR: ", err)
            callback(false, null, err)
        }
    }
}

export function APIGetOrders() {
    return async dispatch => {
        try {
            dispatch(getOrder({
                data:[],
                orderLoadStatus:MCJ.STATUS_LOADING
            }))
            let rsp = await fetch('/api/customer/order')
            HandleResponseError(rsp, dispatch)
            if (rsp.status === 200) {
                let data = (await rsp.json()).data
                dispatch(getOrder({
                    data,
                    orderLoadStatus:MCJ.STATUS_LOAD_OK
                }))
            } else {
                //
            }
        } catch(err) {
            console.log('get order err', err)
        }
    }
}

export function PayOrderAction(status) {
    return dispatch => {
        dispatch(payOrder(status))
    }
}

export function APIConfirmOrder(succeed, amazonPaySessionId = undefined, amazonPayRef = undefined) {
    return async dispatch => {
        try {
            let url = `/api/customer/order?succeed=${succeed}`
            if (amazonPaySessionId !== undefined) {
                url += `&sessionId=${amazonPaySessionId}`
            }
            if (amazonPayRef !== undefined) {
                url += `&reference=${amazonPayRef}`
            }
            await fetch(url, {
                method:'put'
            })
            dispatch(APIGetUserInfo())
            dispatch(APIGetCart())
            dispatch(APIGetOrders())
        } catch (err) {
            dispatch(APIGetUserInfo())
            dispatch(APIGetCart())
            dispatch(APIGetOrders())
        }
    }
}
