import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
// import { Spinner } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import Logo from '../../common/logo'
import './signin.css'

class Confirm extends Component {
    constructor() {
        super()
        this.state = {
            status:""
        }
    }

    componentDidMount() {
        //
        const {token} = this.props
        fetch(`/api/anonymous/confirm?token=${token}`, {
            method:"PUT"
        }).then(res=>{
            // eslint-disable-next-line eqeqeq
            if(res.status == 200) {
                this.setState({
                    status:"OK"
                })
            } else {
                this.setState({
                    status:"failed"
                })
            }
        })
        .catch(err => {
            this.setState({
                status:"error"
            })
        })
    }

    render() {
        var region = this.props.region || ""
        if (region.length > 0) {
            region = `/${region}`
        }
        let content = null
        // eslint-disable-next-line eqeqeq
        if (this.state.status == "OK") {
            content = <div className='resetSucc'>
                <p>Welcome to McJ TOOLS!</p>
                <p>Your registration is Complete.</p>
                <Link to={`${region}/signin`}><Button variant='warning'>SignIn Now</Button></Link>
            </div>
        // eslint-disable-next-line eqeqeq
        } else if (this.state.status == "error"){
            content = <>
                <p>Confirm register email error.</p>
            </>
        // eslint-disable-next-line eqeqeq
        } else if (this.state.status == "failed") {
            content = <>
            <p>Confirm register email failed.</p>
        </>
        }

        return (
            <div className='root'>
            <div className='top'>
                <Logo />
                <div className='signinBox'>
                    <h3>Confirm Email</h3>
                    <hr/>
                    <div className='confirmForm'>
                        {content}
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

function ConfirmEmail() {
    const { region, token } = useParams()

    return <Confirm region={region} token={token} />
}

export default ConfirmEmail