import React from 'react'
import { connect } from 'react-redux'
import EditAddress from './editAddress'
import { useLocation } from "react-router-dom";
import * as queryString from "query-string";

function EditAddressWrap(props) {
    const { addressId } = queryString.parse(useLocation().search)
    return <EditAddress addressId={addressId} />
}

const mapStateToProps = state => ({
})

export default connect(mapStateToProps, null)(EditAddressWrap)