const MCJ = {
    // Shopping status
    SHOPPING_INIT:"SHOPPING_INIT",
    SHOPPING_ADDING:"SHOPPING_ADDING",
    SHOPPING_ADDED:"SHOPPING_ADDED",
    SHOPPING_ADD_FAILED:"SHOPPING_ADD_FAILED",

    // auth status
    AUTH_INIT: "AUTH_INIT",
    AUTH_REQUESTING:"AUTH_REQUESTING",
    AUTH_OK: "AUTH_OK",
    AUTH_FAILED: "AUTH_FAILED",

    DefaultImgPrefix:"https://mcjtools-assets.s3.amazonaws.com/images",

    STATUS_INIT:"INIT",
    STATUS_LOADING:"LOADING",
    STATUS_LOAD_OK:"LOADED",
    STATUS_CONFIRMED:"CONFIRMED",
    STATUS_ERROR:"ERROR",

    PAYMENT_INIT:"INIT",
    PAYMENT_PAYING:"PAYING",
    PAYMENT_OK:"PAY_OK",
    PAYMENT_ERR:"PAY_ERROR",

    SHARE_STR:"begin",
    STRIPE_KEY:'',

    BANNER_HEIGHT: 58
}

export default MCJ