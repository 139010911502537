

export const OrderStatusFGColor = (status) => {
    return (status === 'CREATED') ? 'black' : 'white'
}

export const OrderStatusBGColor = (status) => {
    if (status === 'CREATED') {
        return '#f9ba39'
    } else if (status === 'PAID') {
        return '#235888'
    } else if (status === 'PARTIAL') {
        return '#357D8A'
    } else if (status === 'FULFILLED') {
        return '#4C9C86'
    } else {
        return '#808080'
    }
}