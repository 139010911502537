import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { APISaveUserInfo } from '../../../apis/user'
import { AddressDropDown } from '../../../common/addressDropDown'

class UserAccount extends Component {
    constructor() {
        super()
        this.state = {}
    }

    handleEditChanges = e=>{
        let target = e.target
        if (typeof this.state.infoEdit === 'undefined') {
            let newInfo = {
                ...this.props.userState.userInfo,
                [target.name]:target.value
            }
            this.setState(preState => {
                return {
                    ...preState,
                    infoEdit: newInfo
                }
            })
        } else {
            this.setState(preState => {
                return {
                    ...preState,
                    infoEdit: {
                        ...preState.infoEdit,
                        [target.name]:target.value
                    }
                }
            })
        }   
    }

    getEditValue = name => {
        if(typeof this.state.infoEdit !== 'undefined') {
            return this.state.infoEdit[name]
        }
        return undefined
    }

    getDefaultValue = name => {
        let returnValue = undefined
        if (typeof this.props.userState.userInfo !== 'undefined') {
            returnValue = this.props.userState.userInfo[name]
        }
        if (name === 'country' && returnValue === undefined) {
            returnValue = 'US'
        }
        return returnValue
    }

    saveChanges = ()=>{
        if (typeof this.state.infoEdit !== 'undefined') {
            let inObj = Object.assign({}, this.state.infoEdit)
            delete inObj.roles
            this.props.APISaveUserInfo(inObj)
        }
    }

    renderEditItem = (title, key) => {
        return (
            <tr key={key}>
                <td className='keyCol'><span>{title}</span></td>
                <td><input name={key} className='editInput' onChange={this.handleEditChanges} defaultValue={this.getDefaultValue(key)} /></td>
            </tr>
        )
    }

    renderUserBasicInfo = () => {
        let fullName = this.renderEditItem('Full Name', 'fullName')
        let phone = this.renderEditItem('Phone Number', 'phone')
        let contactEmail = this.renderEditItem('Contact Email', 'contactEmail')
        let company = this.renderEditItem('Company', 'company')
        let association = this.renderEditItem('Association', 'association')

        let account = null
        if (typeof this.props.userState !== 'undefined' && this.props.userState !== null) {
            account = <tr key={'account'}>
                <td className='keyCol'><span>Account</span></td>
                <td><span style={{ marginLeft: '8px' }}>{this.props.userState.userInfo?.email}</span></td>
            </tr>
        }
        let taxExempted = null
        if (typeof this.props.userState !== 'undefined' && this.props.userState !== null &&
            typeof this.props.userState.userInfo !== 'undefined' && this.props.userState.userInfo !== null &&
            this.props.userState.userInfo.taxExempt === true) {
                taxExempted = <tr key={'exempt'}>
                <td className='keyCol'><span>Tax</span></td>
                <td><span style={{ marginLeft: '8px' }}>Exempted</span></td>
            </tr>
        }
        let dealerPartner = null
        if (typeof this.props.userState !== 'undefined' && this.props.userState !== null &&
            typeof this.props.userState.userInfo !== 'undefined' && this.props.userState.userInfo !== null &&
            this.props.userState.userInfo.dealer === true) {
                dealerPartner = <tr key={'dealer'}>
                <td className='keyCol'><span>McJTools</span></td>
                <td><span style={{ marginLeft: '8px' }}>Dealer/Partner</span></td>
            </tr>
        } 

        return (
            <table>
                <tbody>
                    {account}
                    {taxExempted}
                    {dealerPartner}
                    {fullName}
                    {phone}
                    {contactEmail}
                    {company}
                    {association}
                </tbody>
            </table>
        )
    }

    renderShippingAddress = () => {
        let city = this.renderEditItem('City', 'city')
        let address = this.renderEditItem('Address', 'address')
        let postal = this.renderEditItem('Postal', 'postal')
        let state = this.renderEditItem('State', 'state')
        let country = this.renderEditItem('Country', 'country')
        const system = this.props.userState.system
        if (typeof system !== 'undefined' && system !== null) {
            let currCountry = this.getEditValue('country') ?? this.getDefaultValue('country')
            if (typeof currCountry === 'undefined' || currCountry === null || currCountry === "") {
                currCountry = "US"
                this.handleEditChanges({ target: { name: 'country', value: 'US' } })
            }
            country = (
                <tr key='country'>
                    <td style={{ width: '150px', textAlign: 'right' }}><span>Country</span></td>
                    <td>
                        <div className='dropdownList'>
                            <AddressDropDown choiceKey='country' choiceValue={currCountry} choices={system.countries} onClick={(e) => {
                                this.handleEditChanges(e)
                            }} />
                        </div>
                    </td>
                </tr>
            )
            const states = (system.countries.find(c => c.abbrev.toLowerCase() === currCountry.toLowerCase()) || { states: [] }).states
            let currState = this.getEditValue('state') ?? this.getDefaultValue('state')
            state = (
                <tr key='state'>
                    <td style={{ width: '150px', textAlign: 'right' }}><span>State</span></td>
                    <td>
                        <div className='dropdownList'>
                            <AddressDropDown choiceKey='state' choiceValue={currState} choices={states} onClick={(e) => {
                                this.handleEditChanges(e)
                            }} />
                        </div>
                    </td>
                </tr>
            )
        }
        return <table>
            <tbody>
                {address}
                {city}
                {postal}
                {country}
                {state}
            </tbody>
        </table>
    }

    render(){
        let userBasicInfo = this.renderUserBasicInfo()
        let shippingAddress = this.renderShippingAddress()
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginBottom: '16px' }}>
                    <h2>Your Account</h2>
                    <hr/>
                    {userBasicInfo}
                </div>
                <div style={{ marginTop: '16px', marginBottom: '16px' }}>
                    <h3>Your Shipping Address</h3>
                    <hr/>
                    {shippingAddress}
                </div>
                <div>
                    <hr/>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant='warning' onClick={this.saveChanges} >Save Changes</Button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userState:state.userState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        APISaveUserInfo
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAccount)