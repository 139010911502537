import React, { Component } from 'react'
import { Outlet } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import "./user.css"
import { connect } from 'react-redux'
import UserNav from './userNav'
import { 
    APIGetUserInfo
} from '../../../apis/user'

class User extends Component {

    constructor() {
        super()
        this.state = {
            //infoEdit
        }
    } 

    componentDidMount() {
        this.props.APIGetUserInfo()
    }

    render() {
        return (
            <div className='UserContainer container'>        
                <UserNav />
                <div style={{ flexGrow: 1 }}>
                    <Outlet />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userState:state.userState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        APIGetUserInfo
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(User)