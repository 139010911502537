import { bindActionCreators } from '@reduxjs/toolkit'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
    APIUpdateOEMOrder,
} from '../../apis/oemOrder'
import './oemOrderCard.css'
import { OrderStatusFGColor, OrderStatusBGColor } from './oemOrder'
import { withRouter } from '../../common/withRouter'
import OEMOrderCardEdit from './oemOrderCardEdit'
import OEMOrderCardContacts from './oemOrderCardContacts'

class OEMOrderCard extends Component {

    constructor() {
        super()
        this.state = {
        }
    }

    componentDidMount() {
    }

    renderOrderHeader = (order) => {
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                    <span> { order.name } </span>
                </div>
                <div style={{ marginLeft: '8px', marginRight: '8px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <span>Status: { order.status }</span>
                </div>
                <div style={{ marginLeft: '8px', marginRight: '8px' }}>
                    <OEMOrderCardEdit order={ order } />
                </div>
            </div>
        )
    }

    renderOrderInfo = (order) => {
        return (
            <div className='OrderCardSummary'>
                <span>{ order.description }</span>
            </div>
        )
    }

    renderUserOrderProduct = (orderItem) => {
        const productId = orderItem.productId
        const system = this.props.usersState.system || {}
        const productMap = this.props.productState.productMap || {}
        const prodInfo = productMap[productId]
        if (typeof prodInfo === 'undefined' || prodInfo === null) {
            return null
        }
        let prodImage = system.imageUrlPrefix+prodInfo.thumbnail
        if (prodInfo.thumbnail === '') {
            prodImage = '/images/placeholder.png'
        }
        let prodPrice = `$${orderItem.price}`
        let prodName = prodInfo.name || ""
        const maxNameLength = 64
        prodName = prodName.length > maxNameLength ? prodName.substring(0,maxNameLength-1) : prodName
        return (
            <div key={productId} className='OrderCardProd'> 
                <img className='OrderCardProdImage' src={prodImage} alt={orderItem.name} />
                <div className='OrderCardProdInfo'>
                    <span className='OrderCardProdLink' title={`${prodInfo.id}. ${prodName}`}>
                        {prodInfo.id}. {prodName}
                    </span>
                    <Link 
                        to={`/products/${prodInfo.id}`} 
                        className='OrderCardProdLink'
                        title={`{prodInfo.id}. {prodName}`}>
                        {prodInfo.id}. {prodName}
                    </Link>
                    <span>MSKU: {orderItem.msku}</span>
                    <span>OMSKU: {orderItem.omsku}</span>
                    <span>{prodPrice} x {orderItem.quantity}</span>
                </div>
            </div>
        )
    }
    
    renderUserOrder = (order) => {
        if (typeof order === 'undefined' || order === null) {
            return null
        }
        const orderHeader = this.renderOrderHeader(order)
        const orderInfo = this.renderOrderInfo(order)
        const orderContacts = <OEMOrderCardContacts order={ order } />
        return (
            <div key={order.id} className='OrderCard'>
                {/* Header */}
                <div className='OrderCardHeader' style={{
                    color: OrderStatusFGColor(order.status),
                    backgroundColor: OrderStatusBGColor(order.status)
                }}>
                    {orderHeader}
                </div>
                {orderInfo}
                {/* Contacts */}
                <div style={{
                    color: 'white',
                    padding: '8px',
                    fontSize: '14px',
                    backgroundColor: '#808080'
                }}>
                    Contacts
                </div>
                {orderContacts}
            </div>
        )
    }

    render() {
        const order = this.props.order
        const isListMode = this.props.isListMode || false
        const isDisplayMode = this.props.isDisplayMode || false
        if (typeof order === 'undefined' || order === null) {
            return null
        }
        const orderView = this.renderUserOrder(order, isListMode, isDisplayMode)
        return (
            <>
                {orderView}
            </>
        )
    }
}

const mapStateToProps = state => ({
    authState: state.authState,
    usersState: state.usersState,
    productState: state.productState
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        APIUpdateOEMOrder
    }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OEMOrderCard))