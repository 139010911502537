import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './userNav.css'
import '../../../common/common.css'
import { bindActionCreators } from 'redux';
import {APISignOut} from '../../../apis/auth'
import { connect } from 'react-redux';
import { withRouter } from '../../../common/withRouter'

class UserNav extends Component {

    render(){
        var region = this.props.router.params.region || ""
        if (region.length > 0) {
            region = `/${region}`
        }
        return (
        <div className='userNav'>
            <Link style={{ textDecoration: 'none' }} to={'account'}>Your Account</Link>
            { (region !== "/au" && region !== "/nz") && (
                <Link style={{ textDecoration: 'none' }} to={'order'}>Your Orders</Link>
            )}
            <Link style={{ textDecoration: 'none' }} to={'addresses'}>Your Addresses</Link>
            <Link style={{ textDecoration: 'none' }} to={'changepsw'}>Change Password</Link>
            <Link style={{ textDecoration: 'none', color: "red" }} to={`${region}/`} onClick={()=>this.props.APISignOut()}>Sign Out</Link>         
        </div>
        )
    }
}

const mapDispatchToProps = disptach => {
    return bindActionCreators({
        APISignOut
    }, disptach)
}

export default withRouter(connect(null,mapDispatchToProps)(UserNav))